<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker'
import dayjs from 'dayjs'

import useForm from '@/hooks/use-form'
import { ActivityLogType } from '@/types/activity-log'
import { MinimalResource, uuid } from '@/types/general'
import { LicenseType } from '@/types/company'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'
import { DropdownStringOption } from '@/types/inputs'
import { useAuthStore } from '@/stores/auth-store'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'
import { useModuleLogTypes } from '@/hooks/use-module-log-types'

import MyInputLabel from '@/components/my-components/form/MyInputLabel.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

interface Form {
    logTypes: ActivityLogType[]
    userId: uuid | null
    date: Date[]
}

const emit = defineEmits<{
    (e: 'fetch', filters: Form): void
}>()

const { t } = useI18n()
const authStore = useAuthStore()
const users = useLazyLoadedList<DropdownStringOption>(fetchUsers)
const presetRanges = usePresetRangeList()
const logTypes = useModuleLogTypes(t)

const { data, reset } = useForm<Form>({
    logTypes: [],
    userId: null,
    date: [dayjs().subtract(30, 'days').startOf('day').toDate(), dayjs().endOf('day').toDate()],
})

const selectedModules = ref<LicenseType[]>([])
const defaultDate = [
    dayjs().subtract(30, 'days').startOf('day').toDate(),
    dayjs().endOf('day').toDate(),
]

const modules = computed(() => {
    return [
        {
            value: LicenseType.PackagingModule,
            label: t('packaging'),
            color: 'bg-red-400/30 ring-2 ring-red-400 hover:bg-red-400/80',
            selectedColor: 'bg-red-400 ring-2 ring-red-400 hover:bg-red-400/60',
        },
        {
            value: LicenseType.PODModule,
            label: t('pod'),
            color: 'bg-primary-400/30 ring-2 ring-primary-400 hover:bg-primary-400/80',
            selectedColor: 'bg-primary-400 ring-2 ring-primary-400 hover:bg-primary-400/60',
        },
        {
            value: LicenseType.DamageReportModule,
            label: t('damageReport'),
            color: 'bg-yellow-400/30 ring-2 ring-yellow-400 hover:bg-yellow-400/80',
            selectedColor: 'bg-yellow-400 ring-2 ring-yellow-400 hover:bg-yellow-400/60',
        },
        {
            value: LicenseType.DriverReportModule,
            label: t('driverReports'),
            color: 'bg-green-400/30 ring-2 ring-green-400 hover:bg-green-400/80',
            selectedColor: 'bg-green-400 ring-2 ring-green-400 hover:bg-green-400/60',
        },
        {
            value: LicenseType.DeliveryManagementModule,
            label: t('deliveryManagement'),
            color: 'bg-blue-400/30 ring-2 ring-blue-400 hover:bg-blue-400/80',
            selectedColor: 'bg-blue-400 ring-2 ring-blue-400 hover:bg-blue-400/60',
        },
    ]
})

const companyModules = computed(() => {
    return modules.value.filter((module) => authStore.hasLicense(module.value))
})

const hasActiveFilters = computed(() => {
    return (
        data.logTypes.length !== 0 ||
        data.userId !== null ||
        JSON.stringify(data.date) !== JSON.stringify(defaultDate) ||
        selectedModules?.value.length !== 0
    )
})

const filteredLogTypes = computed(() => {
    if (selectedModules.value.length === 0) {
        // All log types
        return Object.values(logTypes).flatMap((module) => module ?? [])
    }
    return selectedModules.value.flatMap((module) => logTypes[module] || [])
})

function selectModule(licenseModule: LicenseType) {
    if (selectedModules.value?.includes(licenseModule)) {
        selectedModules.value = selectedModules.value.filter((module) => module !== licenseModule)
    } else {
        selectedModules.value = [...selectedModules.value, licenseModule]
    }

    // Set log types from the selected modules
    if (selectedModules.value.length === 0) {
        data.logTypes = []
    } else {
        data.logTypes = filteredLogTypes.value.map((type) => type.value)
    }
}

function resetFilters() {
    reset()
    selectedModules.value = []
}

async function fetchUsers(): Promise<DropdownStringOption[]> {
    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.employees', { company: authStore.companyId, web: true }),
    )
    return response.data.map((user) => ({ value: user.id, label: user.name }))
}

watch(data, () => {
    if (data.logTypes.length === 0) {
        selectedModules.value = []
    }
    emit('fetch', data)
})
</script>

<template>
    <div class="px-4 pb-5 pt-4">
        <div class="flex items-start justify-between text-xs mb-2">
            <h3 class="text-lg font-semibold uppercase" v-text="t('filters')" />
            <div
                v-if="hasActiveFilters"
                class="flex items-center space-x-0.5 cursor-pointer hover:text-primary-200"
                @click="resetFilters"
            >
                <mdi:close />
                <h3 class="font-semibold uppercase" v-text="t('reset')" />
            </div>
        </div>

        <!-- Modules -->
        <div class="flex items-center p-1">
            <mdi:package />
            <MyInputLabel class="!pl-0 ml-1 !cursor-default" v-text="t('modules')" />
        </div>
        <div class="text-xs flex flex-wrap w-full">
            <div
                v-for="moduleLicense in companyModules"
                :key="moduleLicense.value"
                class="p-2 m-2 rounded-full items-center cursor-pointer"
                :class="
                    selectedModules.includes(moduleLicense.value)
                        ? moduleLicense.selectedColor
                        : moduleLicense.color
                "
                @click="selectModule(moduleLicense.value)"
            >
                <span v-text="moduleLicense.label" />
            </div>
        </div>

        <!-- Log Types -->
        <div class="mt-4">
            <div class="flex items-center p-1">
                <mdi:clipboard-edit />
                <MyInputLabel class="!pl-0 ml-1 !cursor-default" v-text="t('logTypes')" />
            </div>
            <div class="flex items-center">
                <MySelect
                    v-model="data.logTypes"
                    name="logTypes"
                    group-class="w-full"
                    multiple
                    :options="filteredLogTypes"
                    searchable
                    clear-button
                />
            </div>
        </div>

        <!-- Users -->
        <div class="mt-4">
            <div class="flex items-center p-1">
                <mdi:account />
                <MyInputLabel class="!pl-0 ml-1 !cursor-default" v-text="t('user')" />
            </div>
            <div class="flex items-center">
                <MySelect
                    v-model="data.userId"
                    name="user"
                    group-class="w-full"
                    :options="users.items.value"
                    :loading="users.fetching.value"
                    searchable
                    clear-button
                    @focus="users.fetch()"
                />
            </div>
        </div>

        <!-- Date Range -->
        <div class="mt-5">
            <MyInputLabel class="!cursor-default" v-text="t('selectFromToDate')" />
            <Datepicker
                v-model="data.date"
                class="input-field w-full rounded-xl border border-gray-300"
                range
                :enable-time-picker="false"
                auto-apply
                :teleport="true"
                :preset-dates="presetRanges"
                close-on-scroll
                auto-position
                position="left"
                format="yyyy-MM-dd"
                :transitions="false"
                :placeholder="t('allTime')"
            />
        </div>
    </div>
</template>
