<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { PaginationData } from '@/types/general'
import { DropdownOption } from '@/types/inputs'
import { GetDataParameters } from '@/types/table'

import MyButton from '@/components/my-components/MyButton.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'

export interface Props {
    paginationData?: PaginationData
    refetch?: (parameters?: Partial<GetDataParameters>) => Promise<void>
    rowCount: number
    loadingCount?: boolean
    hidePerPage?: boolean
}

const { t } = useI18n()
const props = defineProps<Props>()
const perPage = ref(props.paginationData?.perPage ?? 15)
const perPageOptions = ref<DropdownOption[]>([
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 40, label: '40' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
])
const formattedPagination = computed(() => {
    const formatter = new Intl.NumberFormat()
    return {
        from: formatter.format(props.paginationData?.from ?? 0),
        to: formatter.format(props.paginationData?.to ?? 0),
        total: formatter.format(props.paginationData?.total ?? 0),
        currentPage: formatter.format(props.paginationData?.currentPage ?? 1),
        lastPage: formatter.format(props.paginationData?.lastPage ?? 1),
    }
})

function changePage(page: number) {
    if (props.refetch && props.paginationData) {
        props.refetch({ page: page })
    }
}

watch(perPage, () => {
    if (props.refetch) {
        props.refetch({ page: 1, 'per-page': perPage.value })
    }
})
</script>

<template>
    <div class="box-border flex items-center justify-between p-2.5 lg:p-4">
        <div v-if="props.paginationData">
            <MySelect
                v-if="!hidePerPage"
                v-model="perPage"
                :label="t('perPage')"
                :options="perPageOptions"
                class="w-16"
            />
        </div>

        <div
            v-if="props.paginationData"
            class="flex items-center"
            :class="{ 'opacity-25': props.loadingCount }"
        >
            {{ formattedPagination.from || 0 }} {{ t('to').toLowerCase() }}
            {{ formattedPagination.to || 0 }}
            {{ t('of').toLowerCase() }}
            {{ formattedPagination.total }}

            <MyButton
                v-tooltip="t('firstPage')"
                :disabled="props.paginationData.currentPage === 1 || props.loadingCount"
                class="mx-2"
                icon
                type="button"
                scheme="light"
                size="small"
                @click="changePage(1)"
            >
                <mdi:first-page />
            </MyButton>

            <MyButton
                v-tooltip="t('previousPage')"
                :disabled="props.paginationData.currentPage === 1 || props.loadingCount"
                icon
                type="button"
                scheme="light"
                size="small"
                @click="changePage(props.paginationData.currentPage - 1)"
            >
                <mdi:chevron-left />
            </MyButton>

            <div
                class="px-4 py-1.5 -mx-1 text-sm font-semibold uppercase"
                v-text="
                    t('pageOfPage', {
                        page: formattedPagination.currentPage,
                        total: formattedPagination.lastPage,
                    })
                "
            />

            <MyButton
                v-tooltip="t('nextPage')"
                :disabled="
                    props.paginationData.currentPage === props.paginationData.lastPage ||
                    props.loadingCount
                "
                icon
                class="mr-2"
                type="button"
                scheme="light"
                size="small"
                @click="changePage(props.paginationData.currentPage + 1)"
            >
                <mdi:chevron-right />
            </MyButton>

            <MyButton
                v-tooltip="t('lastPage')"
                :disabled="
                    props.paginationData.currentPage === props.paginationData.lastPage ||
                    props.loadingCount
                "
                icon
                type="button"
                scheme="light"
                size="small"
                @click="changePage(props.paginationData.lastPage)"
            >
                <mdi:last-page />
            </MyButton>
        </div>

        <div
            v-else
            class="w-full text-right"
            v-text="t('numberOfRows', { rows: props.rowCount })"
        />
    </div>
</template>
