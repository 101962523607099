<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import dayjs from 'dayjs'
import { ref, watch } from 'vue'
import Datepicker, { DatePickerInstance } from '@vuepic/vue-datepicker'
import { useRoute } from 'vue-router'

import { useAuthStore } from '@/stores/auth-store'
import { PaginatedResponse } from '@/types/general'
import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { MinimalBooking } from '@/types/delivery-management'
import { GetDataParameters } from '@/types/table'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'
import { datetime } from '@/utils/dates'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import ActionRowItem from '@/components/table/ActionRowItem.vue'

interface FetchBookingsParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        'start-date': string | undefined
        'end-date': string | undefined
    }
}

const { t } = useI18n()
const authStore = useAuthStore()
const route = useRoute()
const presetRanges = usePresetRangeList()

const { data, paginationData, params, loading, error, refetch } = usePaginatedTable<
    MinimalBooking,
    FetchBookingsParams
>(
    async (params) => {
        const response = await axios.get<PaginatedResponse<MinimalBooking>>(
            window.route('dm.company.bookings.index', {
                company: authStore.companyId,
            }),
            { params: params },
        )

        return response.data
    },
    {
        filter: {
            'start-date': dayjs().subtract(6, 'days').startOf('day').toDate().toISOString(),
            'end-date': dayjs().endOf('day').toDate().toISOString(),
        },
    },
)

const datepicker = ref<DatePickerInstance>(null)
const date = ref<Date[]>(
    params.value.filter['start-date'] && params.value.filter['end-date']
        ? [
              dayjs(params.value.filter['start-date']).toDate(),
              dayjs(params.value.filter['end-date']).toDate(),
          ]
        : [],
)

function setFilter(type: string | number | null, filterName: string) {
    params.value.filter[filterName] = type
    params.value.page = 1
}

function clearDates() {
    datepicker.value?.closeMenu()
    date.value = []
    delete params.value.filter['start-date']
    delete params.value.filter['end-date']
}

watch(date, () => {
    if (!date.value[0] || !date.value[1]) return
    setFilter(dayjs(date.value[0]).startOf('day').toISOString(), 'start-date')
    setFilter(dayjs(date.value[1]).endOf('day').toISOString(), 'end-date')
})
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'dm' }" v-text="t('deliveryManagement')" />
        <Breadcrumb current v-text="t('bookings')" />
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <MyTable
            table-id="dm-bookings"
            :rows="data"
            :pagination-data="paginationData"
            :loading="loading"
            :error="error"
            :get-data="refetch"
            :entity-name="t('bookings')"
            :has-time-range="!!params.filter['start-date']"
        >
            <template #filters>
                <Datepicker
                    ref="datepicker"
                    v-model="date"
                    input-class-name="w-64"
                    range
                    :enable-time-picker="false"
                    auto-apply
                    :teleport="true"
                    :clearable="false"
                    :preset-dates="presetRanges"
                    close-on-scroll
                    position="left"
                    format="yyyy-MM-dd"
                    :transitions="false"
                    :placeholder="t('allTime')"
                    :config="{ closeOnAutoApply: true }"
                >
                    <template #allTime>
                        <button
                            class="dp__btn dp--preset-range"
                            @click="clearDates()"
                            v-text="t('allTime')"
                        />
                    </template>
                </Datepicker>
            </template>

            <template #actionRow="{ row }">
                <RouterLink
                    :to="{
                        name: 'dm.bookings.show',
                        params: { bookingId: row.id },
                        query: route.query,
                    }"
                >
                    <ActionRowItem>
                        <mdi:eye />
                    </ActionRowItem>
                </RouterLink>
            </template>

            <MyTableColumn :name="t('customer')" property="customerCompany.name" />
            <MyTableColumn :name="t('bookingNumber')" property="bookingNumber" />
            <MyTableColumn :name="t('shipmentNumbers')" property="shipments" :sortable="false" />
            <template #shipments="{ row }">
                <div class="flex">
                    <span
                        class="w-full truncate"
                        v-text="
                            row.shipments
                                .map((s) => s.shipmentNumber)
                                .filter((number) => number)
                                .join(', ')
                        "
                    />
                </div>
            </template>
            <MyTableColumn :name="t('hauliers')" property="hauliers" :sortable="false" />
            <template #hauliers="{ row }">
                <div class="flex">
                    <span
                        class="w-full truncate"
                        v-text="row.shipments.map((s) => s.haulierName).join(', ')"
                    />
                </div>
            </template>
            <MyTableColumn :name="t('createdAt')" property="createdAt" />
            <template #createdAt="{ row }">
                <span v-text="datetime(row.createdAt)" />
            </template>

            <template #noDataButton>
                <MyButton
                    v-if="params.filter['start-date']"
                    plain
                    scheme="light"
                    size="small"
                    @click="clearDates()"
                >
                    <mdi:magnify class="mr-2 h-4 w-4" />
                    {{ t('searchAllTime') }}
                </MyButton>
            </template>
        </MyTable>
    </MyPanel>
</template>
