<script lang="ts" setup>
import { DashboardWidget, WidgetType } from '@/types/dashboard'
import { isWidgetOfType } from '@/utils/dashboard'

import StockWidget from '@/components/dashboard/widgets/packaging/StockWidget.vue'
import BalanceWidget from '@/components/dashboard/widgets/packaging/BalanceWidget.vue'
import EmployeeTransactionsWidget from '@/components/dashboard/widgets/packaging/EmployeeTransactionsWidget.vue'
import WidgetExample from '@/components/dashboard/widgets/WidgetExample.vue'

export interface Props {
    widget: DashboardWidget<WidgetType>
    preview?: boolean
}

const props = defineProps<Props>()
</script>

<template>
    <WidgetExample
        v-if="isWidgetOfType(props.widget, WidgetType.Example)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <StockWidget
        v-if="isWidgetOfType(props.widget, WidgetType.PackagingStock)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <BalanceWidget
        v-if="isWidgetOfType(props.widget, WidgetType.PackagingBalance)"
        :widget="props.widget"
        :preview="props.preview"
    />

    <EmployeeTransactionsWidget
        v-if="isWidgetOfType(props.widget, WidgetType.PackagingEmployeeTransactions)"
        :widget="props.widget"
        :preview="props.preview"
    />
</template>
