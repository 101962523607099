<script lang="ts" setup generic="T extends Record<string, unknown>">
import {
    debouncedWatch,
    promiseTimeout,
    useDebounceFn,
    useMousePressed,
    useResizeObserver,
} from '@vueuse/core'
import { computed, onBeforeMount, provide, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { type PaginationData } from '@/types/general'
import { useColumnOrdering } from '@/hooks/table/use-column-ordering'
import { useColumnPreferences } from '@/hooks/table/use-column-preferences'
import { useColumnResizing } from '@/hooks/table/use-column-resizing'
import { useColumnSorting } from '@/hooks/table/use-column-sorting'
import useColumnFiltering from '@/hooks/table/use-column-filtering'
import {
    ColumnSize,
    GetDataParameters,
    InternalTableColumn,
    columnSizesKey,
    columnsKey,
    insertColumnKey,
    organizeColumnsKey,
    removeColumnKey,
    updateColumnKey,
    refetchKey,
} from '@/types/table'
import { getPropertyValue, setColumnOrder } from '@/utils/my-table'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import ActionRow from '@/components/table/internal/ActionRow.vue'
import SettingsPanel from '@/components/table/internal/SettingsPanel.vue'
import TableFilters from '@/components/table/internal/TableFilters.vue'
import PaginationFooter from '@/components/PaginationFooter.vue'
import ColumnFilterDropdown from '@/components/table/internal/ColumnFilterDropdown.vue'

export interface Props<Props extends Record<string, unknown>> {
    tableId: string
    rows?: Props[]
    paginationData?: PaginationData
    getData?: (options?: Partial<GetDataParameters>) => Promise<void>
    loading?: boolean
    loadingCount?: boolean
    error?: boolean
    entityName?: string
    hasTimeRange?: boolean
    rowKey?: string
    rowHeight?: number
    rounded?: boolean
    disableSearch?: boolean
    disableOrdering?: boolean
    disableFooter?: boolean
    disableActions?: boolean
    disableSorting?: boolean
    enableRowSelection?: boolean
    rowSelection?: number[]
    disabledSelectionRows?: number[]
    disableColumnPreferences?: boolean
}

const props = withDefaults(defineProps<Props<T>>(), {
    rowKey: 'id',
    rowHeight: 40,
    disableSearch: false,
    disableOrdering: false,
    disableFooter: false,
    disableActions: false,
    disableSorting: false,
    enableRowSelection: false,
    disableColumnPreferences: false,
    rowSelection: () => [],
    disabledSelectionRows: () => [],
    loading: false,
    loadingCount: false,
    error: false,
})
const emit = defineEmits<{
    (e: 'update:row-selection', value: number[]): void
}>()

const route = useRoute()
const { t } = useI18n()
const { pressed: mousePressed } = useMousePressed()
const wrapperRef = ref<HTMLDivElement>()
const actionRef = ref<HTMLDivElement>()
const searchQuery = ref<string>(String(route.query.query ?? ''))
const columns = ref(new Map<string, InternalTableColumn>())
const columnSizes = ref(new Map<string, ColumnSize>())

const shownColumns = computed(() => {
    return Array.from(columns.value.values())
        .filter((column) => !column.hidden)
        .sort((a, b) => (a.order > b.order ? 1 : -1))
})

const tableWidth = computed(() => {
    return Array.from(columnSizes.value.entries()).reduce((acc, [property, { width }]) => {
        if (columns.value.get(property)?.hidden) return acc

        return acc + width
    }, 0)
})

/** Column management **/
function insertColumn(column: InternalTableColumn) {
    const savedColumn = columnPreferences.value.columns.find((c) => c.property === column.property)

    column.order = savedColumn?.order ?? Infinity
    column.hidden = savedColumn?.hidden ?? column.hidden
    columnSizes.value.set(column.property, {
        width: savedColumn?.width ?? 0,
        x: 0,
    })
    columns.value.set(column.property, column)
    organizeColumns()
}

function removeColumn(column: InternalTableColumn) {
    columns.value.delete(column.property)
    columnSizes.value.delete(column.property)
    organizeColumns()
}

function updateColumn(property: string, column: Partial<InternalTableColumn>) {
    let organize = false
    const currentColumn = columns.value.get(property)
    if (!currentColumn) return

    if (currentColumn.hidden !== column.hidden) {
        organize = true
    }

    columns.value.set(property, { ...currentColumn, ...column })
    if (organize) organizeColumns()
}

let initialized = false
const organizeColumns = useDebounceFn((refit = false) => {
    let columnWidth = 0
    if (!initialized) setColumnOrder(columns)
    if (!initialized || refit) {
        const rect = wrapperRef.value?.getBoundingClientRect()
        const fixedWidth = shownColumns.value.flatMap(({ width }) => (width ? width : []))
        const totalFixedWidth = fixedWidth.reduce((acc, width) => acc + width!, 0)
        columnWidth =
            ((rect?.width ?? 0) - totalFixedWidth) / (shownColumns.value.length - fixedWidth.length)
    }

    let x = 0
    shownColumns.value.forEach((column) => {
        let width = columnSizes.value.get(column.property)?.width ?? 0
        width = width === 0 || refit ? column.width || columnWidth : width
        width = Math.max(width, column.minWidth)
        columnSizes.value.set(column.property, { width, x })
        x += width
    })

    if (!initialized) {
        initialized = true
        onInitialized()
    }
}, 2)

provide(insertColumnKey, insertColumn)
provide(updateColumnKey, updateColumn)
provide(removeColumnKey, removeColumn)
provide(organizeColumnsKey, organizeColumns)
provide(columnsKey, columns)
provide(columnSizesKey, columnSizes)
provide(refetchKey, props.getData)

const { resizedColumn, onResizeStart, onResizeEnd, onResize } = useColumnResizing(
    columns,
    columnSizes,
    organizeColumns,
)
const { draggedColumn, onOrderStart, onOrderEnd, onOrder } = useColumnOrdering(
    wrapperRef,
    columns,
    columnSizes,
    organizeColumns,
)
const { sortedColumn, sortedAscending, onSort } = useColumnSorting(async () => {
    if (!props.getData) return

    let sort = undefined
    if (sortedColumn.value) {
        sort = (sortedAscending.value ? '' : '-') + sortedColumn.value
    }

    await props.getData({ sort })
})
const { columnPreferences, onInitialized } = useColumnPreferences(
    () => props.tableId,
    columns,
    columnSizes,
    props.disableColumnPreferences,
)
const columnFiltering = useColumnFiltering()

function selectAllChanged(checked: boolean) {
    if (checked) {
        const selections = (props.rows || [])
            .map((_, index) => index)
            .filter((index) => !props.disabledSelectionRows.includes(index))
        emit('update:row-selection', selections)
    } else {
        emit('update:row-selection', [])
    }
}

function toggleSelection(index: number, checked: boolean) {
    if (checked) {
        emit('update:row-selection', [...props.rowSelection, index])
    } else {
        const selections = props.rowSelection.filter((i) => i !== index)
        emit('update:row-selection', selections)
    }
}

function onMousemove(e: MouseEvent) {
    onResize(e)
    onOrder(e)
}

function sortClicked(column: InternalTableColumn) {
    if (props.disableSorting || draggedColumn.value || resizedColumn.value) return

    onSort(column)
}

function displayColumn(row: Record<string, unknown>, column: InternalTableColumn): unknown {
    const value = getPropertyValue(row, column.property)

    if (column.booleanColumn) return !!value

    return value
}

function hoverRow(index: number) {
    if (draggedColumn.value) return
    removeRowHover()
    document
        .querySelectorAll(`.${props.tableId} .my-table-row.row-${index}`)
        .forEach((row) => row.classList.add('hover'))
}

function removeRowHover() {
    document.querySelectorAll('.my-table-row.hover').forEach((row) => row.classList.remove('hover'))
}

async function resetTable() {
    // We'll need to wait to make sure new table preferences are set
    await promiseTimeout(1)
    initialized = false
    const columnData = Object.fromEntries(columns.value)
    columns.value.clear()

    for (const [, column] of Object.entries(columnData)) {
        insertColumn(column)
    }
}

useResizeObserver(actionRef, () => {
    if (!columnPreferences.value.columns) {
        initialized = false
        organizeColumns(true)
    }
})

onBeforeMount(async () => {
    if (props.getData) await props.getData()
})

watch(mousePressed, () => {
    if (!mousePressed.value) {
        onResizeEnd()
        onOrderEnd()
        document.body.style.cursor = 'auto'
        document.body.style.userSelect = 'auto'
    }
})

watch(() => props.tableId, resetTable)

debouncedWatch(
    searchQuery,
    async () => {
        if (props.getData) {
            await props.getData({
                query: searchQuery.value,
                page: 1,
            })
        }
    },
    { debounce: 600 },
)

debouncedWatch(
    columnFiltering.filtersAsQueryString,
    async () => {
        if (props.getData) {
            await props.getData({
                page: 1,
                columnFilters: columnFiltering.filtersAsQueryString.value,
            })
        }
    },
    { debounce: 600 },
)
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
    <section
        ref="table"
        :class="{
            'rounded-b-xl': props.disableFooter,
            [props.tableId]: true,
        }"
        class="my-table"
        v-bind="$attrs"
    >
        <div :class="{ relative: !props.disableSearch }">
            <div
                v-if="!disableActions || $slots.filters"
                class="z-10"
                :class="[
                    {
                        'relative flex justify-between border-b border-primary-200 dark:border-dark-400':
                            $slots.filters,
                        'absolute right-0 top-0': !$slots.filters || props.disableSearch,
                    },
                ]"
            >
                <TableFilters
                    v-if="$slots.filters"
                    :column-filters="columnFiltering.filtersAsQueryString.value"
                    @reset-filters="columnFiltering.clearAllFilters()"
                >
                    <slot name="filters" />
                </TableFilters>

                <SettingsPanel v-if="!disableActions" />
            </div>

            <div
                v-if="!props.disableSearch"
                :class="{ 'before:rounded-t-xl': props.rounded }"
                class="w-full grow border-b border-primary-200 before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:z-0 before:transition dark:border-dark-400"
            >
                <MyInput
                    v-model="searchQuery"
                    :placeholder="t('search')"
                    background="bg-transparent"
                    border="border-none"
                    class="group relative h-12 w-full grow rounded-none"
                    shadow="focus:shadow-none"
                >
                    <template #icon>
                        <mdi:magnify />
                    </template>
                    <button
                        v-if="searchQuery.length > 0"
                        class="absolute right-5 top-0 bottom-0 z-20 my-auto hidden h-4 w-4 items-center justify-center rounded-full text-[.6rem] group-focus-within:flex dark:bg-dark-500 dark:hover:bg-dark-500/50"
                        @click="searchQuery = ''"
                    >
                        <mdi:close />
                    </button>
                </MyInput>
            </div>
        </div>

        <!-- Min height is set to avoid the table jumping to much when switching between filters -->
        <div class="relative min-h-[80px]">
            <div class="hidden">
                <slot />
            </div>

            <div class="relative" @mousemove="onMousemove">
                <!-- Table -->
                <div class="relative flex">
                    <LoaderWrapper :visible="props.loading" />

                    <!-- Row selection -->
                    <div
                        v-if="props.enableRowSelection"
                        v-memo="[props.rows, props.rowKey, props.rowSelection]"
                        class="w-10 flex-shrink-0 print:hidden"
                    >
                        <div
                            class="flex h-10 items-center justify-center border-r border-primary-200 bg-primary-100 text-xs font-semibold uppercase dark:border-dark-400 dark:bg-dark-600"
                        >
                            <MyCheckbox
                                :checked="props.rowSelection.length !== 0"
                                :indeterminate="
                                    props.rowSelection.length > 0 &&
                                    props.rowSelection.length !== (props.rows || []).length
                                "
                                class="gap-0"
                                @change="selectAllChanged"
                            />
                        </div>

                        <div
                            v-for="(row, index) in props.rows"
                            :key="'selection-' + row[props.rowKey]"
                            :style="{ height: props.rowHeight + 'px' }"
                            class="selector box-border flex justify-center border-r border-t border-primary-200 bg-primary-50 text-sm odd:bg-primary-100 dark:border-dark-400 dark:odd:bg-dark-600 dark:even:bg-dark-500"
                            :class="`my-table-row row-${index}`"
                            @mouseenter="hoverRow(index)"
                            @mouseleave="removeRowHover"
                        >
                            <MyCheckbox
                                :disabled="props.disabledSelectionRows.includes(index)"
                                :checked="props.rowSelection.includes(index)"
                                class="gap-0"
                                @change="toggleSelection(index, $event)"
                            />
                        </div>
                    </div>

                    <div
                        ref="wrapperRef"
                        class="relative grow bg-primary-100 dark:bg-dark-600"
                        :class="
                            (props.rows?.length ?? 0) > 0 ? 'overflow-x-auto' : 'overflow-hidden'
                        "
                    >
                        <!-- Table header -->
                        <div
                            :style="{ width: tableWidth + 'px' }"
                            class="my-table-columns group flex h-10 justify-between bg-primary-100 dark:bg-dark-600"
                        >
                            <!-- Table columns -->
                            <div
                                v-for="(column, index) in shownColumns"
                                :key="column.property"
                                :class="{
                                    'cursor-pointer': column.sortable,
                                    'my-table-column--dragging border-l bg-primary-100 dark:bg-dark-600':
                                        draggedColumn === column.property,
                                    'transform duration-300':
                                        draggedColumn && draggedColumn !== column.property,
                                }"
                                :style="{
                                    width: columnSizes.get(column.property)!.width + 'px',
                                    transform: `translateX(${
                                        columnSizes.get(column.property)!.x
                                    }px)`,
                                }"
                                class="my-table-column absolute z-0 box-border flex h-10 items-center border-r border-primary-200 px-3 text-xs uppercase last:overflow-hidden last:border-r-0 hover:z-10 dark:border-dark-400 group/item"
                                @click="sortClicked(column)"
                            >
                                <span
                                    v-if="!props.disableOrdering && column.draggable"
                                    class="my-table-column__reorder hidden grow-0 cursor-grab lg:block"
                                    @mousedown="onOrderStart($event, column.property)"
                                >
                                    <mdi:drag />
                                </span>

                                <span class="overflow-hidden">
                                    <slot :name="column.property + 'Header'">
                                        <div
                                            class="truncate whitespace-nowrap font-semibold print:whitespace-pre-wrap"
                                            v-text="column.name"
                                        />
                                    </slot>
                                </span>

                                <div class="ml-auto flex h-full items-center">
                                    <mdi:chevron-down
                                        v-if="sortedColumn === column.property"
                                        :class="{
                                            'rotate-180': sortedAscending,
                                        }"
                                        class="mr-2 transition-all"
                                    />
                                </div>

                                <ColumnFilterDropdown
                                    :column="column"
                                    :filters="columnFiltering.filters.value[column.property] ?? []"
                                    @add-filter="columnFiltering.addFilter(column)"
                                    @remove-filter="columnFiltering.removeFilter(column, $event)"
                                    @clear-filters="columnFiltering.clearFilters(column)"
                                />

                                <div
                                    v-if="index > 0"
                                    class="absolute -left-[5px] top-0 hidden h-full w-[9px] cursor-col-resize items-center lg:flex"
                                    @mousedown="onResizeStart(shownColumns[index - 1].property)"
                                    @mouseup.stop="onResizeEnd"
                                />
                                <div
                                    class="absolute -right-[5px] top-0 h-full w-[9px] cursor-col-resize items-center justify-center print:hidden lg:flex"
                                    @mousedown="onResizeStart(column.property)"
                                    @mouseup.stop="onResizeEnd"
                                >
                                    <div class="h-full w-[1px] bg-primary-200 dark:bg-dark-400" />
                                </div>
                            </div>
                            <!-- Table columns ends -->
                        </div>
                        <!-- Table header ends -->

                        <!-- Table body -->
                        <div
                            :style="{ width: tableWidth + 'px' }"
                            class="items box-border min-w-full"
                        >
                            <!-- Table row -->
                            <div
                                v-for="(row, index) in props.rows"
                                :key="row[props.rowKey] as string"
                                :style="{ height: props.rowHeight + 'px' }"
                                class="group relative box-border flex w-full justify-between border-t border-primary-200 text-table odd:bg-primary-50 dark:border-dark-400 dark:odd:bg-dark-500 dark:even:bg-dark-600"
                                :class="`my-table-row row-${index}`"
                                @mouseenter="hoverRow(index)"
                                @mouseleave="removeRowHover"
                            >
                                <div
                                    v-for="column in shownColumns"
                                    :key="row[props.rowKey] + column.property"
                                    :style="{
                                        width: columnSizes.get(column.property)!.width + 'px',
                                        transform: `translateX(${
                                            columnSizes.get(column.property)!.x
                                        }px)`,
                                    }"
                                    :class="{
                                        [(column.class as string) || '']: true,
                                        'transition duration-300': draggedColumn,
                                    }"
                                    class="absolute box-border flex h-full items-center whitespace-nowrap border-r border-primary-200 px-3 last:border-r-0 dark:border-dark-400"
                                >
                                    <span
                                        v-if="column.booleanColumn"
                                        class="flex w-full items-center justify-center"
                                    >
                                        <mdi:check-bold
                                            v-if="displayColumn(row, column)"
                                            class="text-green-500"
                                        />
                                    </span>

                                    <div
                                        v-else
                                        v-truncate-tooltip
                                        :class="{
                                            'w-full truncate print:whitespace-pre-wrap':
                                                !column.booleanColumn,
                                        }"
                                    >
                                        <slot :row="row" :index="index" :name="column.property">
                                            {{ displayColumn(row, column) }}
                                        </slot>
                                    </div>
                                </div>
                            </div>
                            <!-- Table row ends -->
                        </div>
                        <!-- Table body ends -->
                    </div>

                    <!-- Actions -->
                    <div
                        v-if="$slots.actionMenu || $slots.actionRow"
                        ref="actionRef"
                        v-memo="[props.rows, props.rowKey]"
                        class="flex-shrink-0 print:hidden"
                    >
                        <div
                            class="flex h-10 min-w-[3rem] items-center border-l border-primary-200 bg-primary-100 text-xs font-semibold uppercase dark:border-dark-400 dark:bg-dark-600"
                        />

                        <div
                            v-for="(row, index) in props.rows"
                            :key="'actions' + row[props.rowKey]"
                            :style="{ height: props.rowHeight + 'px' }"
                            class="box-border flex border-t border-primary-200 bg-primary-50 text-sm odd:bg-primary-100 dark:border-dark-400 dark:odd:bg-dark-600 dark:even:bg-dark-500"
                            :class="`my-table-row row-${index}`"
                            @mouseenter="hoverRow(index)"
                            @mouseleave="removeRowHover"
                        >
                            <div
                                class="relative flex w-full items-center justify-end space-x-1 border-l border-primary-200 px-2 dark:border-dark-400"
                            >
                                <slot :row="row" name="actionRow" />

                                <ActionRow
                                    v-if="$slots.actionMenu"
                                    :row="row"
                                    menu-items-class=" -translate-y-2/4 right-8 w-[200px]"
                                >
                                    <slot :row="row" name="actionMenu" />
                                </ActionRow>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Table ends -->

                <slot name="noData">
                    <div
                        v-if="props.rows && props.rows.length === 0 && !props.loading"
                        :class="{
                            'border-t border-primary-200 dark:border-dark-400':
                                !props.disableFooter,
                        }"
                        class="flex flex-col items-center space-y-2 justify-center py-4"
                    >
                        <span
                            v-if="props.hasTimeRange && props.entityName"
                            v-text="
                                t('noEntitiesFoundTimeRange', {
                                    entity: props.entityName.toLowerCase(),
                                })
                            "
                        />
                        <span
                            v-else-if="props.entityName"
                            v-text="
                                t('noEntitiesFound', { entity: props.entityName.toLowerCase() })
                            "
                        />
                        <span v-else class="self-center" v-text="t('noDataToShow')" />
                        <slot name="noDataButton" />
                    </div>
                </slot>
            </div>
        </div>

        <PaginationFooter
            v-if="!props.disableFooter"
            class="border-t border-primary-200 dark:border-dark-400"
            :pagination-data="props.paginationData"
            :refetch="props.getData"
            :row-count="props.rows?.length ?? 0"
            :loading-count="props.loadingCount"
        />
    </section>
</template>

<style>
.my-table .my-table-row.hover {
    @apply bg-primary-300 dark:bg-dark-700;
}

.my-table .my-table-row.hover > div {
    @apply border-dark-300;
}

.my-table .my-table-row.selector.hover {
    @apply border-r-dark-300;
}

.my-table-column--dragging {
    z-index: 20 !important;
}

.my-table-column__reorder {
    @apply opacity-0 transition-all;
    width: 0;
}

.my-table-column:hover .my-table-column__reorder,
.my-table-column--dragging .my-table-column__reorder {
    @apply opacity-100;
    width: 20px;
}
</style>
