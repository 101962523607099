<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'

import { DeviationLog, DeviationLogType } from '@/types/delivery-management'
import useForm from '@/hooks/use-form'
import { datetime } from '@/utils/dates'
import { FileAttachment, uuid } from '@/types/general'
import { useFileUploader } from '@/hooks/use-file-uploader'
import { useAuthStore } from '@/stores/auth-store'
import { IncludedCompany } from '@/types/company'
import { emitter } from '@/utils/mitt'

import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyImageViewer from '@/components/my-components/MyImageViewer.vue'
import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyFileAttachment from '@/components/my-components/MyFileAttachment.vue'
import MyFileSelect from '@/components/my-components/MyFileSelect.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'

interface Props {
    modelId: uuid
    collaboratingCompany?: IncludedCompany
    canFlipResponsibleCompany?: boolean
    isTicket: boolean
    logs: DeviationLog[]
    // This prop is only used for the ColliScannedAsDelivered log type to display the colli numbers
    colliNumbers?: Record<string, string>
}

interface Form {
    isTicket: boolean
    note: string
    files: FileAttachment[]
    flipResponsibleCompany: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'logAdded'): void }>()

const authStore = useAuthStore()
const { t } = useI18n()

const bob = ref(false)
const hideEmptyLogs = ref(true)

const { data, loading, errors, reset, submit } = useForm<Form>({
    note: '',
    files: [],
    isTicket: props.isTicket,
    flipResponsibleCompany: props.canFlipResponsibleCompany,
})
const { filesUploading, uploadFiles } = useFileUploader({
    addFile(file) {
        data.files.push(file)
    },
    updateFile(fileId, path) {
        data.files = data.files.map((file) => {
            if (file.id === fileId) return { ...file, path }

            return file
        })
    },
})

const logTypes = computed<Record<DeviationLogType, string>>(() => {
    return {
        [DeviationLogType.Created]: props.isTicket ? t('ticketCreated') : t('deviationCreated'),
        [DeviationLogType.Solved]: t('markedAsSolved'),
        [DeviationLogType.Closed]: t('markedAsClosed'),
        [DeviationLogType.Escalated]: t('deviationEscalated'),
        [DeviationLogType.AssignedToUser]: t('assignedToUser'),
        [DeviationLogType.Comment]: t('comment'),
        [DeviationLogType.Reopened]: t('reopened'),
        [DeviationLogType.UpdatedTags]: t('updatedTags'),
        [DeviationLogType.ColliScannedAsDelivered]: t('colliScannedAsDelivered'),
    }
})

const visibleLogs = computed(() => {
    return hideEmptyLogs.value
        ? props.logs.filter((log) => log.note !== null || log.files.length > 0)
        : props.logs
})

const hideLogsLabel = computed(() => {
    let label = t('hideEmptyLogs')

    if (hideEmptyLogs.value) {
        const hiddenLogs = props.logs.length - visibleLogs.value.length
        label += ' ' + t('hiddenLogCount', { number: hiddenLogs })
    }
    return label
})

function removeFile(file: FileAttachment) {
    data.files = data.files.filter((f) => f !== file)
}

/**
 * The note will contain a list of colli ids
 * We'll split by , and find the colli number for each of them when available
 */
function getColliNumbers(note: string | null): string {
    if (!props.colliNumbers) return ''

    return (note || '')
        .split(',')
        .map((colliId) => props.colliNumbers![colliId.trim()] ?? '')
        .join(', ')
}

async function onSubmit() {
    const response = await submit(
        'POST',
        window.route('dm.company.deviation-logs.store', {
            company: authStore.companyId,
            model: props.modelId,
        }),
    )

    if (response !== undefined) {
        data.flipResponsibleCompany = false
        emit('logAdded')
        emitter.emit('fetchTickets')
        emitter.emit('fetchDeviations')
        reset()
    }
}
</script>

<template>
    <div class="w-full">
        <MyTextarea v-model="data.note" name="note" :label="t('newComment')" />
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <MyCheckbox
                v-if="props.canFlipResponsibleCompany"
                v-model="data.flipResponsibleCompany"
                :label="t('setResponsibleCompany', { company: props.collaboratingCompany?.name })"
                class="mt-2"
            />
            <div class="mt-2 grid grid-cols-4 gap-3">
                <MyFileAttachment
                    v-for="file in data.files"
                    :key="file.id"
                    :file="file"
                    class="mb-3"
                    removable
                    :uploading="filesUploading.includes(file.id)"
                    @on-remove-clicked="removeFile(file)"
                />
            </div>

            <div class="flex justify-between mb-3">
                <MyFileSelect
                    no-border
                    type="image/*,application/pdf"
                    multiple
                    @selected:multiple="uploadFiles"
                >
                    <mdi:attachment class="dark:text-primary-50" />
                </MyFileSelect>

                <MyButton
                    :disabled="loading || filesUploading.length > 0"
                    :loading="bob"
                    scheme="primary"
                >
                    {{ t('addComment') }}
                </MyButton>
            </div>
        </MyForm>

        <MyCheckbox v-model="hideEmptyLogs" :label="hideLogsLabel" />

        <div
            v-for="log in visibleLogs"
            :key="log.id"
            class="mt-3 bg-white dark:bg-dark-400 rounded-xl p-4 text-sm"
        >
            <div class="flex justify-between flex-wrap">
                <div class="flex flex-col w-1/2">
                    <span
                        v-if="log.type === DeviationLogType.AssignedToUser"
                        class="font-semibold"
                        v-text="t('assignedToUser', { user: log.user.name })"
                    />
                    <span v-else class="font-semibold" v-text="logTypes[log.type]" />
                    <span v-text="log.user.name" />
                </div>
                <div class="w-1/2 text-end" v-text="datetime(log.createdAt)" />
                <div
                    v-if="log.type === DeviationLogType.ColliScannedAsDelivered"
                    v-text="getColliNumbers(log.note)"
                />
                <div v-else-if="log.note" v-text="log.note" />
            </div>

            <MyImageViewer v-if="log.files.length > 0" class="grid grid-cols-4 gap-3 mt-2">
                <MyFileAttachment
                    v-for="file in log.files"
                    :key="file.id"
                    class="cursor-pointer"
                    :file="file"
                />
            </MyImageViewer>
        </div>
        <div v-if="visibleLogs.length === 0">
            {{ t('noLogsFound') }}
        </div>
    </div>
</template>
