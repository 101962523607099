<script lang="ts" setup>
import axios, { AxiosRequestConfig } from 'axios'
import { watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { debouncedWatch } from '@vueuse/core'
import { useRoute } from 'vue-router'

import { CustomerLocation } from '@/types/company'
import { PaginatedResponse, PaginationData } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import CustomerLocationCard from '@/components/customers/CustomerLocationCard.vue'
import PaginationFooter from '@/components/PaginationFooter.vue'

export interface Props {
    modelValue: boolean
    selectedLocations: CustomerLocation[]
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
    (e: 'location-selected', value: CustomerLocation): void
}>()

const searchQuery = ref('')
const loading = ref(false)
const locations = ref<CustomerLocation[]>([])
const route = useRoute()

const paginationData = ref<PaginationData>({
    from: 1,
    to: 1,
    perPage: 5,
    lastPage: 1,
    currentPage: Number(route.query.page ?? 1),
    total: 0,
})

async function fetchLocations(page?: number) {
    loading.value = true

    const params: AxiosRequestConfig['params'] = {
        query: searchQuery.value || undefined,
        page: page ?? paginationData.value.currentPage,
        'per-page': 5,
        filter: {
            'exclude-delivery-description-locations': true,
            'exclude-ids': props.selectedLocations.map((location) => location.id).join(','),
        },
    }

    const response = await axios.get<PaginatedResponse<CustomerLocation>>(
        window.route('company.customers.locations.index', {
            company: authStore.companyId,
        }),
        {
            params: params,
        },
    )

    locations.value = response.data.data

    paginationData.value = {
        from: response.data.meta.from,
        to: response.data.meta.to,
        perPage: response.data.meta.per_page,
        lastPage: response.data.meta.last_page,
        currentPage: response.data.meta.current_page,
        total: response.data.meta.total,
    }

    loading.value = false
}

debouncedWatch(
    () => searchQuery.value,
    () => {
        fetchLocations(1)
    },
    { debounce: 300 },
)

function selectLocation(location: CustomerLocation) {
    emit('location-selected', location)
    close()
}

function close() {
    emit('update:modelValue', false)
}

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue) {
            fetchLocations()
        } else {
            searchQuery.value = ''
        }
    },
)
</script>

<template>
    <MyModal
        :max-width="600"
        :value="props.modelValue"
        :back-button="true"
        @back-button-clicked="close"
        @close="close"
    >
        <template #title>{{ t('addCustomerLocation') }}</template>

        <div class="p-4">
            <MyInput v-model="searchQuery" :placeholder="t('search')" type="search">
                <template #icon>
                    <mdi:magnify />
                </template>
            </MyInput>

            <div class="flex flex-col mt-6 gap-3">
                <CustomerLocationCard
                    v-for="location in locations"
                    :key="location.id"
                    :location="location"
                >
                    <template #action>
                        <MyButton scheme="success" type="button" @click="selectLocation(location)">
                            {{ t('add') }}
                        </MyButton>
                    </template>
                </CustomerLocationCard>
            </div>
            <div v-if="locations.length === 0" class="flex justify-center">
                <span class="dark:text-primary-300">{{ t('noAvailableLocations') }}</span>
            </div>
            <PaginationFooter
                class="border-t border-primary-200 dark:border-dark-500 mt-4"
                hide-per-page
                :pagination-data="paginationData"
                :refetch="(params) => fetchLocations(params?.page ?? 1)"
                :row-count="locations.length ?? 0"
            />
        </div>

        <LoaderWrapper :visible="loading" />
    </MyModal>
</template>
