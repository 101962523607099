<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { uniq } from 'lodash'

import { LicenseType } from '@/types/company'
import { LicenseTypes, widgetConfig } from '@/utils/dashboard'
import { useAuthStore } from '@/stores/auth-store'
import { DashboardWidget } from '@/types/dashboard'
import { useDashboardStore } from '@/stores/dashboard-store'

import WidgetResolver from '@/components/dashboard/widgets/WidgetResolver.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyButton from '@/components/my-components/MyButton.vue'

const { t } = useI18n()

const emit = defineEmits<{
    (e: 'addWidget', value: DashboardWidget): void
    (e: 'update:modelValue', value: boolean): void
}>()

export interface Props {
    modelValue: boolean
}

const selectedLicense = ref<LicenseType | null>()

const props = defineProps<Props>()

const authStore = useAuthStore()
const dashboardStore = useDashboardStore()

const availableWidgets = computed(() => {
    return Object.values(widgetConfig).filter((config) => {
        if (config.moduleLicense && !authStore.hasLicense(config.moduleLicense)) return false

        return config.shouldShow ? config.shouldShow(authStore.licenses) : true
    })
})

const mockWidgets = computed(() => {
    const widgets: Record<string, DashboardWidget> = {}

    availableWidgets.value.forEach((widget) => {
        widgets[widget.type] = {
            id: '',
            dashboardId: dashboardStore.currentDashboard!.id,
            type: widget.type,
            x: 1,
            y: 1,
            width: widget.defaults.width,
            height: widget.defaults.height,
            options: {},
        }
    })
    return widgets
})

const licenseModules = computed(() => {
    const licenses = availableWidgets.value
        .map((w) => w.moduleLicense)
        .filter((l): l is LicenseType => !!l)

    return uniq(licenses)
})

const visibleWidgets = computed(() => {
    return availableWidgets.value.filter((widget) => widget.moduleLicense === selectedLicense.value)
})

function addWidget(widget: DashboardWidget) {
    emit('addWidget', JSON.parse(JSON.stringify(widget)))
    emit('update:modelValue', false)
}

onMounted(() => {
    if (licenseModules.value.length === 0) return
    selectedLicense.value = licenseModules.value[0]
})
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="800" @close="emit('update:modelValue', false)">
        <div v-if="licenseModules.length !== 0" class="space-x-3 overflow-x-scroll mb-6">
            <MyButton
                v-for="license in licenseModules"
                :key="license"
                scheme="success"
                :plain="license !== selectedLicense"
                @click="selectedLicense = license"
                v-text="t(LicenseTypes[license]!)"
            />
        </div>
        <div v-else class="text-center font-semibold" v-text="t('NoAvailableWidgets')" />
        <div class="w-full grid grid-cols-2 gap-4 flex-wrap justify-between">
            <MyPanel
                v-for="widget in visibleWidgets"
                :key="widget.type"
                class="flex flex-col p-3 bg-gray-300 dark:bg-gray-800"
                panel-class="w-full outline-gray-300 dark:outline-gray-800 outline-[3px] outline hover:outline-green-400 dark:hover:outline-green-500 transition-all cursor-pointer"
                @click="addWidget(mockWidgets[widget.type])"
            >
                <div class="relative aspect-square select-none pointer-events-none">
                    <div class="absolute w-[200%] h-[200%] scale-50 origin-top-left">
                        <WidgetResolver :widget="mockWidgets[widget.type]" preview />
                    </div>
                </div>
                <div class="flex flex-col mt-2">
                    <span class="font-semibold" v-text="t(widget.name)" />
                    <span
                        v-truncate-tooltip="t(widget.description)"
                        class="w-full truncate"
                        v-text="t(widget.description)"
                    />
                </div>
            </MyPanel>
        </div>
    </MyModal>
</template>
