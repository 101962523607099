import type { ResourceResponse, uuid } from '@/types/general'
import type { CompanyProduct, Product } from '@/types/packaging'

import { RemovableRef, useLocalStorage } from '@vueuse/core'
import axios from 'axios'
import { defineStore } from 'pinia'

import { useAuthStore } from '@/stores/auth-store'
import { DisplayMode } from '@/types/packaging'
import { TransactionProduct } from '@/types/transaction'

type ProductOrderItem = { order: number; id: string }
const productColors = [
    '#069468',
    '#037EA3',
    '#F8BB54',
    '#E76767',
    '#6A8780',
    '#C0DFE8',
    '#829486',
    '#2f7FA8',
    '#E9BF94',
    '#DC2626',
    '#2A534A',
    '#037193',
    '#935F07',
    '#2C323A',
    '#056F4E',
    '#C0DFE8',
    '#51B495',
    '#818892',
    '#4FA5BF',
    '#B3B5B7',
    '#4B5563',
    '#F59E0B',
    '#841717',
    '#939598',
    '#9BD4C3',
    '#B7BBC1',
    '#9ACBDA',
    '#F1A8A8',
    '#FBD89D',
    '#D4D5D6',
]

interface ProductStoreState {
    fetching: boolean
    loaded: boolean
    displayMode: RemovableRef<DisplayMode>
    selectedProducts: CompanyProduct[]
    defaultProducts: CompanyProduct[]
    products: Product[]
    modalOpen: boolean
    colors: Record<uuid, string>
}

export const useProductStore = defineStore('ProductStore', {
    state: (): ProductStoreState => ({
        fetching: false,
        loaded: false,
        selectedProducts: [],
        defaultProducts: [],
        products: [],
        displayMode: useLocalStorage<DisplayMode>('mypallet.product.displaymode', DisplayMode.Card),
        modalOpen: false,
        colors: {},
    }),

    getters: {
        isList: (state) => state.displayMode === DisplayMode.List,
        isCard: (state) => state.displayMode === DisplayMode.Card,
        nonCompanyProducts: (state) => {
            return state.products.filter(
                (product) =>
                    ![...state.defaultProducts, ...state.selectedProducts]
                        .map((p) => p.id)
                        .includes(product.id),
            )
        },
        companyProducts: (state) => {
            return [...state.defaultProducts, ...state.selectedProducts]
        },
    },

    actions: {
        findProduct(id: string) {
            return this.products.find((product) => product.id === id)
        },

        findCompanyProduct(id: string) {
            return this.companyProducts.find((product) => product.id === id)
        },

        companyProductRequest() {
            const authStore = useAuthStore()

            return axios.get<ResourceResponse<CompanyProduct[]>>(
                window.route('packaging.company.products.index', { company: authStore.companyId }),
            )
        },

        setCompanyProducts(products: CompanyProduct[]) {
            this.selectedProducts = products.filter((p) => !p.default)
            this.defaultProducts = products.filter((p) => p.default)
        },

        async fetchCompanyProducts() {
            this.fetching = true

            const response = await this.companyProductRequest()
            this.setCompanyProducts(response.data.data)
            this.setProductColors()

            this.fetching = false
        },

        async fetchProducts() {
            if (!this.loaded) {
                this.fetching = true

                const licensedProductsRequest = axios.get<ResourceResponse<Product[]>>(
                    window.route('packaging.products.index'),
                )

                const [companyProductsResponse, licensedProductsResponse] = await Promise.all([
                    this.companyProductRequest(),
                    licensedProductsRequest,
                ])

                this.setCompanyProducts(companyProductsResponse.data.data)
                this.setProductColors()
                this.products = licensedProductsResponse.data.data
                this.fetching = false
                this.loaded = true
            }
        },

        async addProductToCompany(product: Product) {
            const authStore = useAuthStore()
            this.fetching = true

            await axios.put(
                window.route('packaging.company.products.update', {
                    company: authStore.companyId,
                    product: product.id,
                }),
            )

            this.selectedProducts.push({ ...product, order: 0, favored: false, price: 0 })
            this.fetching = false
        },

        async manageProduct(
            productId: string,
            data: { default?: boolean; price?: number; order?: number } = {},
        ) {
            const authStore = useAuthStore()
            this.fetching = true

            const response = await axios.put<
                { default?: boolean; price?: number; order?: number },
                { data: ResourceResponse<CompanyProduct[]> }
            >(
                window.route('packaging.company.products.update', {
                    company: authStore.companyId,
                    product: productId,
                }),
                data,
            )

            this.setCompanyProducts(response.data.data)
            this.fetching = false
        },

        async removeProductFromCompany(productId: string) {
            const authStore = useAuthStore()
            this.fetching = true

            const response = await axios.delete<ResourceResponse<CompanyProduct[]>>(
                window.route('packaging.company.products.destroy', {
                    company: authStore.companyId,
                    product: productId,
                }),
            )

            this.setCompanyProducts(response.data.data)
            this.fetching = false
        },

        async saveDefaultOrder(products: ProductOrderItem[]) {
            const authStore = useAuthStore()
            this.fetching = true

            const response = await axios.post<
                { products: { order: number; id: string }[] },
                { data: ResourceResponse<CompanyProduct[]> }
            >(
                window.route('packaging.company.products.update-order', {
                    company: authStore.companyId,
                }),
                {
                    products,
                },
            )

            this.setCompanyProducts(response.data.data)
            this.fetching = false
        },

        transactionProducts(): TransactionProduct[] {
            return this.companyProducts.map(
                (p): TransactionProduct => ({
                    ...p,
                    received: 0,
                    delivered: 0,
                    deliveredFilled: false,
                    receivedFilled: false,
                }),
            )
        },

        setProductColors() {
            const colors: Record<uuid, string> = {}
            for (const [index, product] of Object.entries(this.companyProducts)) {
                colors[product.id] = productColors[index as unknown as number]
            }
            this.colors = colors
        },

        getProductColor(index: number): string {
            return productColors[index]
        },
    },
})
