import { parse as qsParse, stringify as qsStringify } from 'qs'
import { createRouter, createWebHistory, LocationQuery, NavigationGuardWithThis } from 'vue-router'

import ForgotPassword from '@/pages/auth/ForgotPassword.vue'
import Login from '@/pages/auth/Login.vue'
import ResetPassword from '@/pages/auth/ResetPassword.vue'
import Companies from '@/pages/Companies.vue'
import ComponentPage from '@/pages/ComponentsPage.vue'
import DamageReports from '@/pages/damage-reports/Reports.vue'
import Dashboard from '@/pages/dashboard/Dashboard.vue'
import PublicDashboard from '@/pages/dashboard/PublicDashboard.vue'
import DriverReports from '@/pages/driver-reports/Reports.vue'
import eCMRShipments from '@/pages/ecmr/Shipments.vue'
import NoModuleLicense from '@/pages/NoModuleLicense.vue'
import NotFoundComponent from '@/pages/NotFoundComponent.vue'
import LocationBalance from '@/pages/packaging/LocationBalance.vue'
import PackagingModule from '@/pages/packaging/Products.vue'
import Statements from '@/pages/packaging/Statements.vue'
import PackagingStock from '@/pages/packaging/Stock.vue'
import PackagingTransactions from '@/pages/packaging/Transactions.vue'
import ViewStatement from '@/pages/packaging/ViewStatement.vue'
import PODReceipts from '@/pages/pod/Receipts.vue'
import Profile from '@/pages/Profile.vue'
import SettingsMessageTemplates from '@/pages/settings/MessageTemplates.vue'
import SettingsEmployees from '@/pages/settings/Employees.vue'
import SettingsLicenses from '@/pages/settings/Licenses.vue'
import SettingsLocations from '@/pages/settings/Locations.vue'
import SettingsMetadata from '@/pages/settings/Metadata.vue'
import SettingsVehiclesAndUnits from '@/pages/settings/VehiclesAndUnits.vue'
import SettingsActivityLog from '@/pages/settings/ActivityLog.vue'
import DeliveryManagementHubs from '@/pages/delivery-management/Hubs.vue'
import DeliveryManagementTours from '@/pages/delivery-management/Tours.vue'
import DeliveryManagementDeviations from '@/pages/delivery-management/Deviations.vue'
import DeliveryManagementTickets from '@/pages/delivery-management/Tickets.vue'
import DeliveryManagementShipments from '@/pages/delivery-management/Shipments.vue'
import DeliveryManagementBookings from '@/pages/delivery-management/Bookings.vue'
import { useAuthStore } from '@/stores/auth-store'
import { LicenseType } from '@/types/company'
import { wrap } from '@/utils/array'
import { firstModuleRoute, moduleLicenses } from '@/utils/licenses'
import Templates from '@/pages/damage-reports/Templates.vue'
import Alarms from '@/pages/Alarms.vue'
import AlarmSettings from '@/pages/settings/AlarmSettings.vue'
import DeliveryManagementCollis from '@/pages/delivery-management/Collis.vue'
import DeliveryManagementDeliveryDescriptions from '@/pages/delivery-management/DeliveryDescriptions.vue'
import { PermissionType } from '@/types/general'

import CustomerPageLayout from '@/layouts/CustomerPageLayout.vue'

import DeviationModal from '@/components/delivery-management/DeviationModal.vue'
import TicketModal from '@/components/delivery-management/TicketModal.vue'
import TourModal from '@/components/delivery-management/tour-modal/TourModal.vue'
import ShipmentModal from '@/components/delivery-management/ShipmentModal.vue'
import DamageReportModal from '@/components/damage-reports/ReportModal.vue'
import TransportObjectModal from '@/components/TransportObjectModal.vue'
import ReportModal from '@/components/driver-reports/ReportModal.vue'
import PassThrough from '@/components/layout/PassThrough.vue'
import TransactionModal from '@/components/packaging/transactions/TransactionModal.vue'
import PODReceiptModal from '@/components/pod/ReceiptModal.vue'
import BookingModal from '@/components/delivery-management/BookingModal.vue'

/**
 * Checks if the current user company has one of the given licenses
 * In case it has not it will redirect to the no module licenses route
 * @param licenses
 * @param showNotFound
 * @param allowsReadonly
 * @returns
 */

function licenseGuard(
    licenses: LicenseType | LicenseType[],
    showNotFound = false,
    allowsReadonly = true,
): NavigationGuardWithThis<undefined> {
    return async function (to, _from, next) {
        if (to.meta.guestsAllowed) return next()

        const authStore = useAuthStore()
        await authStore.licensesLoaded()

        if (to.name === 'dashboard' && authStore.company?.options.dashboardDisabled) {
            next(firstModuleRoute(authStore.licenseList))
        }

        if (!authStore.hasLicense(licenses)) {
            if (showNotFound) {
                return next(firstModuleRoute(authStore.licenseList))
            }
            return next({ name: 'noModuleLicense', params: { license: wrap(licenses)[0] } })
        }

        if (!authStore.hasPermission(PermissionType.ManageCompany) && !allowsReadonly) {
            return next('/')
        }

        return next()
    }
}

const router = createRouter({
    history: createWebHistory(),
    parseQuery: (search) => qsParse(search) as LocationQuery,
    stringifyQuery: (query) => qsStringify(query, { encode: false }),
    routes: [
        {
            path: '/',
            name: 'dashboard',
            alias: '/dashboard',
            meta: { title: 'companyDashboard' },
            component: Dashboard,
            beforeEnter: licenseGuard(
                [LicenseType.PackagingModule, LicenseType.PackagingModuleReadonly],
                true,
            ),
            children: [
                {
                    path: 'dashboard/:slug',
                    name: 'dashboard.show',
                    meta: { title: 'companyDashboard' },
                    component: Dashboard,
                },
            ],
        },
        {
            path: '/dashboards/:dashboardId',
            meta: { title: 'companyDashboard', hideHeader: true },
            component: PublicDashboard,
        },
        {
            path: '/login',
            name: 'login',
            meta: { title: 'login', layout: 'login' },
            component: Login,
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            meta: { title: 'forgotPassword', layout: 'login' },
            component: ForgotPassword,
        },
        {
            path: '/password-reset/:hash',
            name: 'password-reset',
            meta: { title: 'resetPassword', layout: 'login' },
            component: ResetPassword,
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { title: 'profile' },
            component: Profile,
        },
        {
            path: '/component-page',
            name: 'componentPage',
            meta: { title: 'component-page' },
            component: ComponentPage,
        },
        {
            path: '/damage-reports',
            name: 'dmr',
            meta: { title: 'reports' },
            redirect: { name: 'dmr.reports' },
            component: PassThrough,
            beforeEnter: licenseGuard(LicenseType.DamageReportModule),
            children: [
                {
                    path: 'companies',
                    name: 'dmr.companies',
                    meta: {
                        title: 'companies',
                    },
                    component: Companies,
                },
                {
                    path: 'reports',
                    name: 'dmr.reports',
                    meta: { title: 'reports' },
                    component: DamageReports,
                    children: [
                        {
                            path: ':reportId',
                            name: 'dmr.reports.show',
                            meta: {
                                title: 'reports',
                                guestsAllowed: true,
                            },
                            component: DamageReportModal,
                        },
                    ],
                },
                {
                    path: 'vehicles-and-units',
                    name: 'dmr.transport-objects',
                    meta: { title: 'vehiclesAndUnits' },
                    component: SettingsVehiclesAndUnits,
                    children: [
                        {
                            path: ':transportObjectId',
                            name: 'dmr.transport-objects.show',
                            meta: {
                                title: 'vehiclesAndUnits',
                            },
                            component: TransportObjectModal,
                        },
                    ],
                },
                {
                    path: 'templates',
                    name: 'dmr.templates',
                    meta: { title: 'templates' },
                    component: Templates,
                },
                {
                    path: 'alarms',
                    name: 'dmr.alarms',
                    meta: {
                        title: 'alarms',
                    },
                    component: Alarms,
                },
                {
                    path: 'customers/:id',
                    meta: {
                        hideInNavigation: true,
                        title: 'customerView',
                    },
                    component: CustomerPageLayout,
                    name: 'dmr.customers.show',
                },
            ],
        },

        {
            path: '/driver-reports',
            name: 'dr',
            meta: { title: 'reports' },
            redirect: { name: 'dr.reports' },
            component: PassThrough,
            beforeEnter: licenseGuard(LicenseType.DriverReportModule),
            children: [
                {
                    path: 'companies',
                    name: 'dr.companies',
                    meta: {
                        title: 'companies',
                    },
                    component: Companies,
                },
                {
                    path: 'reports',
                    name: 'dr.reports',
                    meta: {
                        title: 'reports',
                    },
                    component: DriverReports,
                    children: [
                        {
                            path: ':reportId',
                            name: 'dr.reports.show',
                            meta: {
                                title: 'reports',
                                guestsAllowed: true,
                            },
                            component: ReportModal,
                        },
                    ],
                },
                {
                    path: 'customers/:id',
                    meta: {
                        hideInNavigation: true,
                        title: 'customerView',
                    },
                    component: CustomerPageLayout,
                    name: 'dr.customers.show',
                },
            ],
        },
        {
            path: '/pod',
            name: 'pod',
            meta: { title: 'pod' },
            redirect: { name: 'pod.receipts' },
            component: PassThrough,
            beforeEnter: licenseGuard(LicenseType.PODModule),
            children: [
                {
                    path: 'receipts',
                    name: 'pod.receipts',
                    meta: {
                        title: 'receipts',
                    },
                    component: PODReceipts,
                    children: [
                        {
                            path: ':receiptId',
                            name: 'pod.receipts.show',
                            meta: {
                                title: 'receipts',
                                guestsAllowed: true,
                            },
                            component: PODReceiptModal,
                        },
                    ],
                },
                {
                    path: 'companies',
                    name: 'pod.companies',
                    meta: {
                        title: 'companies',
                    },
                    component: Companies,
                },
                {
                    path: 'customers/:id',
                    meta: {
                        hideInNavigation: true,
                        title: 'customerView',
                    },
                    component: CustomerPageLayout,
                    name: 'pod.customers.show',
                    children: [
                        {
                            path: 'receipts/:receiptId',
                            name: 'pod.customers.receipts.show',
                            meta: { title: 'receipts', hideInNavigation: true },
                            beforeEnter: licenseGuard(LicenseType.PODModule),
                            component: PODReceiptModal,
                        },
                    ],
                },
            ],
        },
        {
            path: '/packaging',
            name: 'packaging',
            meta: { title: 'packaging' },
            redirect: { name: 'packaging.balance' },
            component: PassThrough,
            beforeEnter: licenseGuard([
                LicenseType.PackagingModule,
                LicenseType.PackagingModuleReadonly,
            ]),
            children: [
                {
                    path: 'balance',
                    name: 'packaging.balance',
                    meta: {
                        title: 'balance',
                    },
                    component: Companies,
                },
                {
                    path: 'location-balance',
                    name: 'packaging.location-balance',
                    meta: {
                        title: 'locationBalance',
                        hideInNavigation: true,
                    },
                    component: LocationBalance,
                },
                {
                    path: 'statement',
                    name: 'packaging.statement',
                    meta: {
                        hideInNavigation: true,
                        title: 'statement',
                    },
                    component: PassThrough,
                    children: [
                        {
                            path: ':statementId',
                            name: 'packaging.statement.show',
                            meta: { title: 'statement', guestsAllowed: true },
                            component: ViewStatement,
                        },
                    ],
                },
                {
                    path: 'customers/:id',
                    meta: {
                        hideInNavigation: true,
                        title: 'customerView',
                    },
                    component: CustomerPageLayout,
                    name: 'packaging.customers.show',
                    children: [
                        {
                            path: 'transactions/:transactionId',
                            name: 'packaging.customers.transactions.show',
                            meta: { title: 'transactions', hideInNavigation: true },
                            beforeEnter: licenseGuard([
                                LicenseType.PackagingModule,
                                LicenseType.PackagingModuleReadonly,
                            ]),
                            component: TransactionModal,
                        },
                    ],
                },
                {
                    path: '/packaging/transactions',
                    name: 'packaging.transactions',
                    meta: {
                        title: 'transactions',
                    },
                    component: PackagingTransactions,
                    children: [
                        {
                            path: ':transactionId',
                            name: 'packaging.transactions.show',
                            meta: { title: 'transactions', guestsAllowed: true },
                            component: TransactionModal,
                        },
                    ],
                },
                {
                    path: 'products',
                    name: 'packaging.products',
                    meta: { title: 'products', requiredLicense: LicenseType.PackagingModule },
                    component: PackagingModule,
                    beforeEnter: licenseGuard(LicenseType.PackagingModule),
                },
                {
                    path: 'stock',
                    name: 'packaging.stock',
                    meta: { title: 'stock', requiredLicense: LicenseType.Stock },
                    component: PackagingStock,
                },
                {
                    path: '/packaging/statements',
                    name: 'packaging.statements',
                    meta: { title: 'statements' },
                    component: Statements,
                    children: [
                        {
                            path: ':statementId',
                            name: 'packaging.statements.show',
                            meta: { title: 'statement', guestsAllowed: true },
                            component: ViewStatement,
                        },
                    ],
                },
                {
                    path: 'alarms',
                    name: 'packaging.alarms',
                    meta: {
                        title: 'alarms',
                    },
                    component: Alarms,
                },
            ],
        },

        {
            path: '/delivery-management',
            name: 'dm',
            meta: { title: 'dm' },
            redirect: { name: 'dm.hubs' },
            component: PassThrough,
            beforeEnter: licenseGuard(LicenseType.DeliveryManagementModule),
            children: [
                {
                    path: 'companies',
                    name: 'dm.companies',
                    meta: {
                        title: 'companies',
                    },
                    component: Companies,
                },
                {
                    path: 'hubs',
                    name: 'dm.hubs',
                    meta: { title: 'hubs' },
                    component: DeliveryManagementHubs,
                },
                {
                    path: 'tours',
                    name: 'dm.tours',
                    meta: { title: 'tours' },
                    component: DeliveryManagementTours,
                    children: [
                        {
                            path: ':tourId',
                            name: 'dm.tours.show',
                            meta: { title: 'tours' },
                            component: TourModal,
                        },
                    ],
                },
                {
                    path: 'shipments',
                    name: 'dm.shipments',
                    meta: { title: 'shipments' },
                    component: DeliveryManagementShipments,
                    children: [
                        {
                            path: ':shipmentId',
                            name: 'dm.shipments.show',
                            meta: {
                                title: 'shipments',
                                guestsAllowed: true,
                            },
                            component: ShipmentModal,
                        },
                        {
                            path: ':transportObjectId',
                            name: 'dm.transport-objects.show',
                            meta: {
                                title: 'vehiclesAndUnits',
                            },
                            component: TransportObjectModal,
                        },
                    ],
                },
                {
                    path: 'bookings',
                    name: 'dm.bookings',
                    meta: { title: 'bookings' },
                    component: DeliveryManagementBookings,
                    children: [
                        {
                            path: ':bookingId',
                            name: 'dm.bookings.show',
                            meta: {
                                title: 'bookings',
                            },
                            component: BookingModal,
                        },
                    ],
                },
                {
                    path: 'collis',
                    name: 'dm.collis',
                    meta: { title: 'collis' },
                    component: DeliveryManagementCollis,
                },
                {
                    path: 'delivery-descriptions',
                    name: 'dm.delivery-descriptions',
                    meta: {
                        title: 'deliveryDescriptions',
                        requiredLicense: LicenseType.ShipmentDeliveryDescriptions,
                    },
                    component: DeliveryManagementDeliveryDescriptions,
                    beforeEnter: licenseGuard(LicenseType.ShipmentDeliveryDescriptions),
                },
                {
                    path: 'deviations',
                    name: 'dm.deviations',
                    meta: { title: 'deviations' },
                    component: DeliveryManagementDeviations,
                    children: [
                        {
                            path: ':deviationId',
                            name: 'dm.deviations.show',
                            meta: {
                                title: 'deviations',
                            },
                            component: DeviationModal,
                        },
                    ],
                },
                {
                    path: 'tickets',
                    name: 'dm.tickets',
                    meta: { title: 'tickets' },
                    component: DeliveryManagementTickets,
                    children: [
                        {
                            path: ':ticketId',
                            name: 'dm.tickets.show',
                            meta: {
                                title: 'tickets',
                            },
                            component: TicketModal,
                        },
                    ],
                },
                {
                    path: 'alarms',
                    name: 'dm.alarms',
                    meta: {
                        title: 'alarms',
                    },
                    component: Alarms,
                },
                {
                    path: 'customers/:id',
                    meta: {
                        hideInNavigation: true,
                        title: 'customerView',
                    },
                    component: CustomerPageLayout,
                    name: 'dm.customers.show',
                    children: [
                        {
                            path: 'shipments/:shipmentId',
                            name: 'dm.customers.shipments.show',
                            meta: { title: 'shipments', hideInNavigation: true },
                            beforeEnter: licenseGuard(LicenseType.DeliveryManagementModule),
                            component: ShipmentModal,
                        },
                        {
                            path: 'tickets/:ticketId',
                            name: 'dm.customers.tickets.show',
                            meta: { title: 'tickets', hideInNavigation: true },
                            beforeEnter: licenseGuard(LicenseType.DeliveryManagementModule),
                            component: TicketModal,
                        },
                    ],
                },
            ],
        },

        {
            path: '/e-cmr',
            name: 'ecmr',
            meta: { title: 'ecmr.shipments' },
            component: eCMRShipments,
            beforeEnter: licenseGuard(LicenseType.ECMRModule),
        },

        {
            path: '/settings',
            name: 'settings',
            redirect: { name: 'settings.metadata' },
            component: PassThrough,
            beforeEnter: licenseGuard(moduleLicenses, true),
            children: [
                {
                    path: 'metadata',
                    name: 'settings.metadata',
                    meta: { title: 'metadata' },
                    component: SettingsMetadata,
                },
                {
                    path: 'locations',
                    name: 'settings.locations',
                    meta: { title: 'locations' },
                    component: SettingsLocations,
                },
                {
                    path: 'employees',
                    name: 'settings.employees',
                    meta: { title: 'employees' },
                    component: SettingsEmployees,
                },
                {
                    path: 'licenses',
                    name: 'settings.licenses',
                    meta: { title: 'licenses' },
                    component: SettingsLicenses,
                    beforeEnter: licenseGuard(moduleLicenses, true, false),
                },
                {
                    path: 'alarms',
                    name: 'settings.alarms',
                    meta: {
                        title: 'alarmNotifications',
                        requiredLicense: [
                            LicenseType.PackagingModule,
                            LicenseType.DamageReportModule,
                        ],
                    },
                    component: AlarmSettings,
                },
                {
                    path: 'vehicles-and-units',
                    name: 'transport-objects',
                    meta: { title: 'vehiclesAndUnits' },
                    component: SettingsVehiclesAndUnits,
                    children: [
                        {
                            path: ':transportObjectId',
                            name: 'transport-objects.show',
                            meta: {
                                title: 'vehiclesAndUnits',
                            },
                            component: TransportObjectModal,
                        },
                    ],
                },
                {
                    path: 'message-templates',
                    name: 'settings.message-templates',
                    meta: { title: 'messageTemplates' },
                    component: SettingsMessageTemplates,
                    beforeEnter: licenseGuard(moduleLicenses, true, false),
                },
                {
                    path: 'activity-log',
                    name: 'settings.activity-log',
                    meta: { title: 'activityLog' },
                    component: SettingsActivityLog,
                },
            ],
        },
        {
            path: '/no-module-license/:license',
            component: NoModuleLicense,
            meta: { title: 'noModuleLicense', hideHeader: true },
            name: 'noModuleLicense',
        },
        {
            path: '/:catchAll(.*)',
            component: NotFoundComponent,
            meta: { title: 'pageNotFound', hideHeader: true },
            name: 'NotFound',
        },
    ],
})

export default router
