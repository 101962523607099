<script lang="ts" setup>
import axios from 'axios'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { MessageTemplate, MessageTemplateType } from '@/types/message-templates'
import { ResourceResponse } from '@/types/general'
import { useMessageTemplateOptions } from '@/utils/message-templates'
import { LicenseType } from '@/types/company'

import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyVariableInput from '@/components/my-components/form/MyVariableInput.vue'

interface Form {
    type: MessageTemplateType
    emailSubject: string
    emailBody: string
    smsBody: string
}

const { t } = useI18n()
const authStore = useAuthStore()
const templateOptions = useMessageTemplateOptions()

const loading = ref(false)
const templates = ref<Record<MessageTemplateType, MessageTemplate>>(window.defaultMessageTemplates)
const selectedTemplate = ref<MessageTemplate | null>(null)
const form = useForm<Form>({
    type: MessageTemplateType.PackagingStatement,
    emailSubject: 'f',
    emailBody: '',
    smsBody: '',
})

const visibleTemplates = computed(() => {
    return Object.values(templates.value).filter((template) => {
        return hasRequiredLicense(template.type)
    })
})

function selectTemplate(template: MessageTemplate) {
    selectedTemplate.value = template
    form.data.type = template.type
    form.data.emailBody = template.emailBody
    form.data.emailSubject = template.emailSubject
    form.data.smsBody = template.smsBody
}

async function fetchTemplates() {
    loading.value = true
    const response = await axios.get<ResourceResponse<MessageTemplate[]>>(
        window.route('company.message-templates.index', { company: authStore.companyId }),
    )

    for (const template of response.data.data) {
        templates.value[template.type] = template
    }
    loading.value = false
    if (visibleTemplates.value.length > 0) {
        selectTemplate(templates.value[visibleTemplates.value[0].type])
    }
}

async function saveTemplate() {
    const response = await form.submit(
        'PUT',
        window.route('company.message-templates.update', { company: authStore.companyId }),
    )

    if (response && selectedTemplate.value) {
        templates.value[selectedTemplate.value.type] = {
            ...templates.value[selectedTemplate.value.type],
            ...form.data,
        }
    }
}

function hasRequiredLicense(type: MessageTemplateType): boolean {
    if (type === MessageTemplateType.PackagingStatement) {
        return authStore.hasLicense(LicenseType.PackagingModule)
    } else if (type === MessageTemplateType.OwnerDamageReportEmail) {
        return authStore.hasLicense(LicenseType.DamageReportModule)
    } else if (type === MessageTemplateType.ShipmentDelayAlert) {
        return authStore.hasLicense(LicenseType.DeliveryManagementModule)
    }

    return false
}

onBeforeMount(() => fetchTemplates())
</script>

<template>
    <div class="mx-auto w-full max-w-5xl">
        <h1 class="text-xl font-bold" v-text="t('messageTemplates')" />

        <div class="relative mt-5 flex min-h-[400px] w-full">
            <LoaderWrapper :visible="loading || form.loading.value" immediate />

            <aside class="h-full w-full max-w-xs flex-shrink-0 space-y-4">
                <div
                    v-for="template in visibleTemplates"
                    :key="template.type"
                    class="w-full cursor-pointer rounded-lg p-5"
                    :class="{
                        'bg-gray-200 dark:bg-dark-500': selectedTemplate?.type !== template.type,
                        'bg-primary-500 text-white': selectedTemplate?.type === template.type,
                    }"
                    @click="selectTemplate(template)"
                    v-text="templateOptions[template.type].title"
                />

                <div
                    v-if="Object.keys(visibleTemplates).length === 0"
                    class="flex h-full w-full items-center justify-center"
                    v-text="t('noTemplatesFound')"
                />
            </aside>

            <MyForm
                v-if="selectedTemplate"
                :errors="form.errors.value"
                class="ml-5 w-full space-y-4"
                @submit.prevent="saveTemplate"
            >
                <h1
                    class="text-lg font-semibold"
                    v-text="templateOptions[selectedTemplate.type].title"
                />

                <div
                    v-if="templateOptions[selectedTemplate.type].emailSubjectShown"
                    class="rounded-lg p-4 bg-dark-100 dark:bg-dark-700"
                >
                    <MyVariableInput
                        v-model="form.data.emailSubject"
                        name="subject"
                        :label="t('emailSubject')"
                        :variables="templateOptions[selectedTemplate.type].variables"
                    />
                </div>

                <div
                    v-if="templateOptions[selectedTemplate.type].emailBodyShown"
                    class="rounded-lg p-4 bg-dark-100 dark:bg-dark-700"
                >
                    <MyVariableInput
                        v-model="form.data.emailBody"
                        name="body"
                        :label="t('emailBody')"
                        :variables="templateOptions[selectedTemplate.type].variables"
                    />
                </div>

                <div
                    v-if="templateOptions[selectedTemplate.type].smsBodyShown"
                    class="rounded-lg p-4 bg-dark-100 dark:bg-dark-700"
                >
                    <MyVariableInput
                        v-model="form.data.smsBody"
                        name="subject"
                        :label="t('smsBody')"
                        :variables="templateOptions[selectedTemplate.type].variables"
                    />
                </div>

                <div class="flex justify-end">
                    <MyButton scheme="primary" v-text="t('save')" />
                </div>
            </MyForm>

            <div v-else class="my-0 flex w-full items-center justify-center self-stretch">
                {{ t('selectAMessageTemplate') }}
            </div>
        </div>
    </div>
</template>
