import { uuid } from '@/types/general'

export interface Dashboard {
    id: uuid
    userId: uuid
    companyId: uuid
    name: string
    slug: string
    private: boolean
    active: boolean
    widgets: DashboardWidget[]
    isEditing: boolean
    shareToken: string | null
}

export enum WidgetType {
    Example = 0,
    PackagingBalance = 1,
    PackagingStock = 2,
    PackagingEmployeeTransactions = 3,
}

export interface DashboardWidget<Type extends WidgetType = WidgetType> {
    id: uuid
    dashboardId: uuid
    type: Type
    x: number
    y: number
    width: number
    height: number
    options: Type extends keyof WidgetOptionsMap ? WidgetOptionsMap[Type] : Record<string, unknown>
}

type WidgetOptionsMap = {
    [WidgetType.PackagingBalance]: BalanceWidgetOptions
    [WidgetType.PackagingStock]: StockWidgetOptions
    [WidgetType.PackagingEmployeeTransactions]: EmployeeTransactionsOptions
}

export enum SortDirection {
    desc = 'desc',
    asc = 'asc',
}

interface BalanceWidgetOptions {
    selectedLocationId?: uuid
    sortDirection?: SortDirection
    products?: uuid[]
}

interface StockWidgetOptions {
    selectedLocationId?: uuid
}

export type EmployeeWidgetOrderBy = '-transactionCount' | 'transactionCount' | 'name'
interface EmployeeTransactionsOptions {
    selectedLocationId?: uuid
    sortBy?: EmployeeWidgetOrderBy
    period?: number
}

export interface DashboardGridRef {
    refreshGrid: () => void
    addWidget: <T extends DashboardWidget>(widget: T) => T
}
