import { useI18n } from 'vue-i18n'
import axios from 'axios'
import download from 'downloadjs'
import { notify } from '@kyvg/vue3-notification'
import { ref } from 'vue'

import { useAuthStore } from '@/stores/auth-store'
import { uuid } from '@/types/general'

/**
 * Use to download a delivery description.
 */
export function useDownloadDeliveryDescription() {
    const { t } = useI18n()
    const authStore = useAuthStore()
    const downloading = ref(false)

    return {
        downloading,
        downloadPDF: async function (deliveryDescriptionId: uuid) {
            try {
                downloading.value = true
                const response = await axios.get(
                    window.route('dm.company.delivery-descriptions.download', {
                        company: authStore.companyId,
                        deliveryDescription: deliveryDescriptionId,
                    }),
                    { responseType: 'blob' },
                )

                const filename = response.headers['content-disposition']
                    .replace('attachment; filename=', '')
                    .replace(/"/g, '')

                download(response.data, filename, 'application/pdf')

                notify({ type: 'success', text: t('pdfDownloadedSuccessfully') })
            } catch (error) {
                notify({ type: 'error', text: t('pdfDownloadFailed') })
            } finally {
                downloading.value = false
            }
        },
    }
}
