export interface StockItem {
    productId: string
    balance: number
    credit: number
    transit: number
    productName: string
    productImage?: string
}

export interface StockLocation {
    locationId: string
    locationName: string
    locationDeleted: boolean
    balance: number
    products: StockItem[]
}

export interface StockProductBalance {
    id: string
    name: string
    image: string
    price: number
    stock: number
    credit: number
    total: number
    totalValue: number
}

export enum StockViewMode {
    Details = 'details',
    Overview = 'overview',
}
