<script lang="ts" setup>
import axios from 'axios'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { PaginatedResponse, ResourceResponse, uuid } from '@/types/general'
import { DashboardWidget, SortDirection, WidgetType } from '@/types/dashboard'
import { useAuthStore } from '@/stores/auth-store'
import { useProductStore } from '@/stores/product-store'
import { useDashboardStore } from '@/stores/dashboard-store'
import { image } from '@/utils/assets'
import { useCalculatedRowCount } from '@/hooks/use-calculated-row-count'
import { DropdownStringOption } from '@/types/inputs'
import { Product } from '@/types/packaging'

import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import PageNavigationLink from '@/components/layout/navigation/PageNavigationLink.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import MyRadioButtonGroup from '@/components/my-components/form/MyRadioButtonGroup.vue'
import MyRadioButton from '@/components/my-components/form/MyRadioButton.vue'
import CompanyLocationOption from '@/components/dashboard/widget-settings/CompanyLocationOption.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'

export interface Props {
    widget: DashboardWidget<WidgetType.PackagingBalance>
    preview?: boolean
}

interface BalanceResponse extends PaginatedResponse<BalanceRow> {
    products: Product[]
}

interface BalanceRow {
    customerId: uuid
    customerCompanyId: uuid
    companyName: string
    total: number
    balances: Record<uuid, number>
}

interface LocationBalanceRow {
    id: uuid
    name: string
    total: number
    balances: Record<uuid, number>
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const productStore = useProductStore()
const dashboardStore = useDashboardStore()

const balanceElement = ref<HTMLElement>()
const loading = ref(false)
const products = ref<Product[]>([])
const customerBalance = ref<BalanceRow[]>([])
const locationBalance = ref<LocationBalanceRow[]>()
const pagination = ref({ pages: 1 })
const expandedRow = ref<uuid>()
const sortedBy = ref<uuid>()
const page = ref(1)
const rowsPerPage = useCalculatedRowCount(balanceElement, {
    rowHeight: 47,
    headerSpacing: 119,
    onChange: fetchBalance,
})

const selectedProductIds = computed({
    get: () => props.widget.options.products ?? [],
    set: (products) => dashboardStore.updateWidgetOptions(props.widget, { products }),
})
const selectedLocationId = computed({
    get: () => props.widget.options.selectedLocationId ?? null,
    set: (id) => {
        dashboardStore.updateWidgetOptions(props.widget, { selectedLocationId: id ?? undefined })
    },
})
const sortDirection = computed({
    get: () => props.widget.options.sortDirection ?? SortDirection.desc,
    set: (sortDirection) => dashboardStore.updateWidgetOptions(props.widget, { sortDirection }),
})

const productsDropdownOptions = computed<DropdownStringOption[]>(() => {
    return productStore.companyProducts.map((product) => ({
        value: product.id,
        label: product.name,
    }))
})

async function toggleRow(id: uuid) {
    if (expandedRow.value === id) {
        expandedRow.value = undefined
        return
    }
    expandedRow.value = id
    locationBalance.value = undefined
    const params = {
        customerCompany: id,
        dashboard: dashboardStore.currentDashboard!.id,
        shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
        'product-ids': selectedProductIds.value,
    }
    const response = await axios.get<ResourceResponse<LocationBalanceRow[]>>(
        window.route('dashboard.product-balance.per-location', params),
    )

    locationBalance.value = response.data.data
}

async function fetchBalance() {
    if (!rowsPerPage.value) return
    loading.value = true
    locationBalance.value = []
    expandedRow.value = undefined
    loading.value = true

    const params: Record<string, string | string[] | number | boolean | null> = {
        dashboard: dashboardStore.currentDashboard!.id,
        shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
        defaults: true,
        'per-page': rowsPerPage.value,
        page: page.value,
        'sort-direction': sortDirection.value,
        'location-id': selectedLocationId.value,
        'product-ids': selectedProductIds.value,
    }

    if (sortedBy.value) {
        params['sort-by'] = sortedBy.value
    }

    const response = await axios.get<BalanceResponse>(
        window.route('dashboard.product-balance', params),
    )

    customerBalance.value = response.data.data
    products.value = response.data.products
    pagination.value.pages = response.data.meta.last_page
    loading.value = false
}

async function handleSort(id?: string) {
    if (sortedBy.value === id && sortDirection.value === SortDirection.desc) {
        sortDirection.value = SortDirection.asc
    } else {
        sortDirection.value = SortDirection.desc
    }
    sortedBy.value = id
}

watch(
    () => props.widget.options,
    async () => await fetchBalance(),
    { deep: true },
)

watch(page, () => fetchBalance())
</script>

<template>
    <MyWidget :widget="props.widget" :preview="props.preview">
        <div class="border-b-2 border-primary-100 dark:border-dark-600 h-full flex flex-col">
            <div ref="balanceElement" class="overflow-y-auto flex-1 w-full">
                <LoaderWrapper v-if="loading" :visible="loading" />
                <table class="w-full min-w-full table-fixed">
                    <thead>
                        <tr class="border-collapse">
                            <td
                                class="text-slate-400 dark:text-slate-200 sticky left-0 w-60 bg-primary-50 px-3 pb-3 text-center align-middle text-xl font-semibold shadow-lg drop-shadow-lg dark:bg-dark-500 dark:shadow-black"
                            />

                            <td
                                class="text-slate-400 dark:text-slate-200 w-24 cursor-pointer p-4 text-center align-bottom text-xs font-medium uppercase"
                                @click="handleSort()"
                            >
                                {{ t('total') }}

                                <mdi:chevron-down
                                    v-if="sortedBy === undefined"
                                    class="float-right transition-transform"
                                    :class="{ 'rotate-180': sortDirection === 'asc' }"
                                />
                            </td>
                            <td
                                v-for="product in products"
                                :key="product.id"
                                class="text-slate-400 dark:text-slate-200 h-30 w-40 rounded-t-xl py-4 font-medium odd:bg-black/5 dark:border-dark-600 dark:odd:bg-black/20"
                            >
                                <div
                                    class="flex h-full cursor-pointer flex-col items-center justify-between"
                                    @click="handleSort(product.id)"
                                >
                                    <img
                                        class="w-28 object-contain"
                                        :src="image(product.image)"
                                        :alt="product.name"
                                    />

                                    <div
                                        class="mt-2 flex items-center justify-between text-center text-xs uppercase"
                                    >
                                        {{ product.name }}
                                        <mdi:chevron-down
                                            v-if="sortedBy === product.id"
                                            class="float-right transition-transform"
                                            :class="{ 'rotate-180': sortDirection === 'asc' }"
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </thead>

                    <tbody>
                        <template v-for="row in customerBalance" :key="row.customerId">
                            <tr
                                class="group odd:bg-primary-100 hover:bg-primary-300 dark:odd:bg-dark-400 dark:hover:bg-dark-700 h-[47px]"
                            >
                                <td
                                    v-truncate-tooltip
                                    class="sticky left-0 truncate bg-primary-50 px-3 shadow-lg drop-shadow-lg group-odd:bg-primary-100 group-hover:bg-primary-300 dark:bg-dark-500 dark:group-odd:bg-dark-400 dark:group-hover:bg-dark-700"
                                >
                                    <mdi:chevron-right
                                        :class="{
                                            'rotate-90': expandedRow === row.customerCompanyId,
                                        }"
                                        class="mr-4 inline-block cursor-pointer transition-transform"
                                        @click="toggleRow(row.customerCompanyId)"
                                    />

                                    <PageNavigationLink
                                        no-background
                                        no-padding
                                        regular-link
                                        class="font-semibold text-primary-400"
                                        :to="{
                                            name: 'packaging.customers.show',
                                            params: { id: row.customerId },
                                        }"
                                    >
                                        {{ row.companyName }}
                                    </PageNavigationLink>

                                    <mdi:loading
                                        :class="{
                                            'opacity-0':
                                                expandedRow !== row.customerCompanyId ||
                                                locationBalance,
                                        }"
                                        class="absolute right-1 top-0 bottom-0 m-auto animate-spin text-gray-400 transition-opacity duration-500"
                                    />
                                </td>

                                <td class="px-3 text-center font-semibold" v-text="row.total" />

                                <td
                                    v-for="product in products"
                                    :key="row.customerId + product.id"
                                    class="px-3 text-center font-semibold odd:bg-black/5 dark:odd:bg-black/20"
                                    v-text="row.balances[product.id] ?? 0"
                                />
                            </tr>

                            <template
                                v-if="expandedRow === row.customerCompanyId && locationBalance"
                            >
                                <tr
                                    v-for="location in locationBalance"
                                    :key="location.id"
                                    class="group odd:bg-primary-100 hover:bg-primary-300 dark:odd:bg-dark-400 dark:hover:bg-dark-700 h-[47px]"
                                >
                                    <td
                                        class="sticky left-0 truncate bg-white pl-12 pr-3 text-sm shadow-lg drop-shadow-lg group-even:bg-primary-50 group-hover:bg-primary-200 dark:bg-secondary-300 dark:group-even:bg-secondary-400 dark:group-hover:bg-black"
                                        v-text="location.name"
                                    />

                                    <td class="px-3 text-center" v-text="location.total" />

                                    <td
                                        v-for="product in products"
                                        :key="location.id + product.id"
                                        class="px-3 text-center odd:bg-black/5 dark:odd:bg-black/20"
                                        v-text="location.balances[product.id] ?? 0"
                                    />
                                </tr>
                            </template>
                        </template>

                        <div
                            v-if="!loading && customerBalance.length === 0"
                            class="m-4"
                            v-text="t('youHaveNoCustomers')"
                        />
                    </tbody>
                </table>
            </div>
            <div class="flex items-center ml-auto my-2 mr-7 flex-shrink-0">
                <MyButton
                    :disabled="page <= 1"
                    icon
                    plain
                    scheme="light"
                    size="small"
                    @click="page--"
                >
                    <mdi:chevron-left />
                </MyButton>

                <div
                    class="mx-2 text-sm font-semibold uppercase"
                    v-text="t('pageOfPage', { page, total: pagination.pages })"
                />

                <MyButton
                    :disabled="page >= pagination.pages"
                    icon
                    plain
                    scheme="light"
                    size="small"
                    @click="page++"
                >
                    <mdi:chevron-right />
                </MyButton>
            </div>
        </div>

        <template #settings>
            <CompanyLocationOption v-model="selectedLocationId" />

            <MySelect
                v-model="selectedProductIds"
                :options="productsDropdownOptions"
                group-class="mt-4  mx-auto"
                multiple
                :label="t('products')"
                clear-button
                @focus="productStore.fetchProducts()"
            />
            <MyRadioButtonGroup v-model="sortDirection" class="mt-4 mx-auto" :label="t('sortBy')">
                <MyRadioButton :label="t('ascending')" :value="SortDirection.asc" />
                <MyRadioButton :label="t('descending')" :value="SortDirection.desc" />
            </MyRadioButtonGroup>
        </template>
    </MyWidget>
</template>
