<script lang="ts" setup>
import type { uuid } from '@/types/general'
import type { Dashboard } from '@/types/dashboard'

import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useDashboardStore } from '@/stores/dashboard-store'

import MyButton from '@/components/my-components/MyButton.vue'
import CreateDashboardModal from '@/components/dashboard/CreateDashboardModal.vue'

const { t } = useI18n()
const dashboardStore = useDashboardStore()

const route = useRoute()
const router = useRouter()

const dashboardModalShown = ref(false)

const hasDashboards = computed(() => {
    return dashboardStore.dashboards.length >= 1
})

function goToDashboard(dashboard: Dashboard) {
    router.push({
        name: 'dashboard.show',
        params: { slug: dashboard.slug },
        query: route.query,
    })
}

function isCurrentDashboard(dashboardId: uuid): boolean {
    return dashboardStore.currentDashboard?.id === dashboardId
}
</script>

<template>
    <div class="space-y-2">
        <div class="flex space-x-3 overflow-y-hidden overflow-x-scroll">
            <MyButton
                v-for="dashboard in dashboardStore.dashboards"
                :key="dashboard.id"
                :scheme="isCurrentDashboard(dashboard.id) ? 'success' : 'primary'"
                :plain="!isCurrentDashboard(dashboard.id)"
                class="flex items-center"
                @click="goToDashboard(dashboard)"
            >
                {{ dashboard.name }}
            </MyButton>
            <MyButton
                v-tooltip="t('createDashboard')"
                scheme="primary"
                :icon="hasDashboards"
                :class="{ 'aspect-square': hasDashboards }"
                @click="dashboardModalShown = !dashboardModalShown"
            >
                <mdi:plus-thick :class="{ 'mr-1': !hasDashboards }" />
                {{ !hasDashboards ? t('createDashboard') : '' }}
            </MyButton>
        </div>
        <CreateDashboardModal :visible="dashboardModalShown" @close="dashboardModalShown = false" />
    </div>
</template>
