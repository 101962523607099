<script setup lang="ts">
import type { DropdownOption } from '@/types/inputs'
import type { DashboardEmployee } from '@/types/user'

import axios from 'axios'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

import { DashboardWidget, WidgetType } from '@/types/dashboard'
import { PermissionType, ApiPaginationData, PaginatedResponse } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { UserType } from '@/types/user'
import { image } from '@/utils/assets'
import { moduleLicenses } from '@/utils/licenses'
import { useDashboardStore } from '@/stores/dashboard-store'
import { useCalculatedRowCount } from '@/hooks/use-calculated-row-count'

import MySelect from '@/components/my-components/form/MySelect.vue'
import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'
import CompanyLocationOption from '@/components/dashboard/widget-settings/CompanyLocationOption.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'

interface Props {
    widget: DashboardWidget<WidgetType.PackagingEmployeeTransactions>
    preview?: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()
const authStore = useAuthStore()
const dashboardStore = useDashboardStore()

const employeesElement = ref<HTMLDivElement>()
const employees = ref<DashboardEmployee[]>([])
const pagination = ref<ApiPaginationData>()
const hasPagination = computed(() => pagination.value && pagination.value.last_page > 1)
const loading = ref(true)
const rowsPerPage = useCalculatedRowCount(employeesElement, {
    rowHeight: 44,
    rowSpacing: 16,
    onChange: fetchEmployees,
})

const selectedLocationId = computed({
    get: () => props.widget.options.selectedLocationId ?? null,
    set: (id) => {
        dashboardStore.updateWidgetOptions(props.widget, { selectedLocationId: id ?? undefined })
    },
})
const sortBy = computed({
    get: () => props.widget.options.sortBy ?? '-transactionCount',
    set: (sortBy) => dashboardStore.updateWidgetOptions(props.widget, { sortBy }),
})
const period = computed({
    get: () => props.widget.options.period ?? 7,
    set: (period) => dashboardStore.updateWidgetOptions(props.widget, { period }),
})

const sortOptions = computed<DropdownOption[]>(() => [
    { label: t('transactionsDescending'), value: '-transactionCount' },
    { label: t('transactionsAscending'), value: 'transactionCount' },
    { label: t('name'), value: 'name' },
])
const periodOptions = computed<DropdownOption[]>(() => [
    { label: t('last7days'), value: 7 },
    { label: t('last14Days'), value: 14 },
    { label: t('last30Days'), value: 30 },
])

function prevPage() {
    if (pagination.value && pagination.value.current_page > 1)
        fetchEmployees(pagination.value.current_page - 1)
}

function nextPage() {
    if (pagination.value && pagination.value.current_page < pagination.value.last_page)
        fetchEmployees(pagination.value.current_page + 1)
}

function getUserType(type: UserType): string {
    if (type === UserType.Driver) return t('driver')
    if (type === UserType.Warehouse) return t('warehouse')

    return '---'
}

async function fetchEmployees(page = 1) {
    if (rowsPerPage.value === undefined) return
    loading.value = true
    const { data: response } = await axios.get<PaginatedResponse<DashboardEmployee>>(
        window.route('dashboard.employees', {
            dashboard: dashboardStore.currentDashboard!.id,
            shareToken: !authStore.user ? dashboardStore.currentDashboard!.shareToken : null,
            page,
            'per-page': rowsPerPage.value,
            sort: sortBy.value,
            startDate: dayjs().subtract(period.value, 'days').toISOString(),
            endDate: new Date().toISOString(),
            location: selectedLocationId.value,
        }),
    )

    employees.value = response.data
    pagination.value = response.meta

    loading.value = false
}

watch(
    () => props.widget.options,
    () => fetchEmployees(1),
)
</script>

<template>
    <MyWidget :widget="props.widget" :preview="props.preview">
        <div class="flex flex-col h-full p-6">
            <h3
                class="relative font-bold uppercase tracking-wide text-primary-400 dark:text-primary-300 flex-shrink-0"
                v-text="t('employees')"
            />

            <section ref="employeesElement" class="h-full space-y-4 mt-4 overflow-y-scroll">
                <LoaderWrapper v-if="loading" :visible="loading" />
                <article
                    v-for="employee in employees"
                    :key="'employee-' + employee.id"
                    class="flex items-center gap-4 h-[44px]"
                >
                    <div
                        class="flex h-full aspect-square items-center justify-center rounded-full bg-primary-300 overflow-hidden"
                    >
                        <img
                            v-if="employee.profileImage"
                            :src="image(employee.profileImage)"
                            :alt="employee.name"
                            class="object-cover"
                        />
                        <span v-else class="text-primary-100"><mdi:account /></span>
                    </div>
                    <div class="grow">
                        <p class="font-semibold text-primary-400" v-text="employee.name" />
                        <p
                            class="text-xs font-semibold text-gray-500"
                            v-text="getUserType(employee.userType)"
                        />
                    </div>
                    <div>
                        <p class="text-[.5rem] uppercase" v-text="t('transactions')" />
                        <p
                            class="text-center text-2xl font-bold text-blue-500"
                            v-text="employee.transactionCount"
                        />
                    </div>
                </article>
            </section>

            <div class="flex items-center mt-4 flex-shrink-0">
                <div class="w-full text-center">
                    <RouterLink
                        v-if="
                            authStore.hasLicense(moduleLicenses) &&
                            authStore.hasPermission(PermissionType.ManageCompany)
                        "
                        class="text-sm font-semibold text-blue-500 underline decoration-2 underline-offset-2"
                        :to="{ name: 'settings.employees' }"
                        v-text="t('goTo', { to: t('employees') })"
                    />
                </div>
                <div v-if="pagination && pagination.last_page > 1" class="flex justify-end gap-1">
                    <a
                        v-if="hasPagination"
                        class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg transition-all hover:bg-primary-400 hover:text-primary-50"
                        :class="{
                            'pointer-events-none opacity-50': pagination.current_page === 1,
                        }"
                        @click.prevent="prevPage"
                    >
                        <mdi:chevron-left />
                    </a>
                    <a
                        v-if="hasPagination"
                        class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg transition-all hover:bg-primary-400 hover:text-primary-50"
                        :class="{
                            'pointer-events-none opacity-50':
                                pagination.current_page === pagination.last_page,
                        }"
                        @click.prevent="nextPage"
                    >
                        <mdi:chevron-right />
                    </a>
                </div>
            </div>
        </div>

        <template #settings>
            <CompanyLocationOption v-model="selectedLocationId" />

            <MySelect
                v-model="sortBy"
                :options="sortOptions"
                group-class="mt-4  mx-auto"
                :label="t('sortBy')"
            />

            <MySelect
                v-model="period"
                :options="periodOptions"
                group-class="mt-4  mx-auto"
                :label="t('period')"
            />
        </template>
    </MyWidget>
</template>
