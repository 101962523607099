<script lang="ts" setup>
import NotFoundComponent from '@/pages/NotFoundComponent.vue'
import { useDashboardStore } from '@/stores/dashboard-store'

import DashboardGrid from '@/components/dashboard/DashboardGrid.vue'

const dashboardStore = useDashboardStore()
</script>

<template>
    <div v-if="!dashboardStore.currentDashboard">
        <NotFoundComponent :hide-crumbs="true" :error-message="'noMatchingDashboard'" />
    </div>
    <DashboardGrid v-else :widgets="dashboardStore.currentDashboard.widgets" />
</template>
