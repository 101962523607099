<script lang="ts" setup>
import { notify } from '@kyvg/vue3-notification'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useDashboardStore } from '@/stores/dashboard-store'

import MyModal from '@/components/my-components/MyModal.vue'
import MyButton from '@/components/my-components/MyButton.vue'

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'update:modelValue', value: boolean): void
}>()

export interface Props {
    modelValue: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()
const dashboardStore = useDashboardStore()

const copyingUrl = ref(false)
const generatingToken = ref(false)

const canShowShareLink = computed(() => dashboardStore.currentDashboard?.shareToken)
const shareLink = computed(() => {
    return window.route('dashboard.show', {
        dashboard: dashboardStore.currentDashboard,
        shareToken: dashboardStore.currentDashboard?.shareToken,
    })
})

function copyLink() {
    if (copyingUrl.value || !dashboardStore.currentDashboard?.shareToken) return
    navigator.clipboard.writeText(shareLink.value)
    copyingUrl.value = true
    notify({ type: 'success', text: t('linkCopied'), duration: 1 })
    setTimeout(() => {
        copyingUrl.value = false
    }, 1000)
}

async function generateDashboardToken() {
    if (generatingToken.value) return

    generatingToken.value = true
    await dashboardStore.generateShareToken()
    generatingToken.value = false
}
</script>

<template>
    <MyModal
        v-if="props.modelValue && dashboardStore.currentDashboard"
        :value="props.modelValue"
        :max-width="650"
        @close="emit('close')"
    >
        <template #title>{{ t('shareEntity', { entity: t('dashboard') }) }}</template>
        <div class="flex flex-col space-y-2">
            <div class="flex place-items-center py-1">
                <span>
                    {{
                        t('dashboardShareLinkDescription', {
                            dashboard: dashboardStore.currentDashboard.name,
                        })
                    }}
                </span>
                <MyButton
                    scheme="primary"
                    class="ml-auto"
                    size="small"
                    :disabled="generatingToken"
                    @click="generateDashboardToken()"
                >
                    {{ t('generateNewDashboardLink') }}
                    <mdi:refresh class="ml-2" :class="{ 'animate-spin': generatingToken }" />
                </MyButton>
            </div>
            <div
                v-if="canShowShareLink"
                class="dark:bg-dark-500 bg-primary-200 rounded-md py-3 px-4 group cursor-pointer select-none relative"
                @click="copyLink"
            >
                <div class="flex place-items-center w-fit absolute right-2">
                    <mdi:clipboard-text
                        class="group-hover:opacity-100 opacity-0 text-primary-100 transition duration-100"
                        :class="{
                            'opacity-100 text-primary-500 dark:text-primary-400': copyingUrl,
                        }"
                    />
                </div>

                <span
                    class="text-base text-primary-500 dark:text-primary-400 font-semibold block max-w-full overflow-hidden truncate pr-2"
                >
                    {{ shareLink }}
                </span>
            </div>
            <span class="text-red-500">
                {{ t('dashboardShareLinkWarning') }}
            </span>
        </div>
    </MyModal>
</template>
