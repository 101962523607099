<script lang="ts" setup>
import { useDark } from '@vueuse/core'
import { computed } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

import LocaleDropdown from '@/components/LocaleDropdown.vue'
import Copyright from '@/components/layout/Copyright.vue'

const darkMode = useDark()
const route = useRoute()

const mypalletLogo = computed(() => {
    if (darkMode.value) {
        return 'https://imagedelivery.net/Dw1gWFsgRYaCpgB4c6PD1Q/94800510-1c2c-43d1-9663-03353d2a7000/public'
    }

    return 'https://imagedelivery.net/Dw1gWFsgRYaCpgB4c6PD1Q/51a8d0e1-f0cc-4a8f-b164-2bbfbd825600/public'
})
</script>

<template>
    <div
        v-if="!route.meta.hideHeader"
        class="mx-auto flex w-full max-w-[2200px] justify-between bg-primary-200 dark:bg-dark-700 lg:p-6"
    >
        <RouterLink class="w-auto py-2.5" to="/">
            <img
                alt="myPallet Logo"
                class="order-1 inline-flex h-[20px] lg:h-[30px]"
                :src="mypalletLogo"
            />
        </RouterLink>

        <div class="flex items-center">
            <LocaleDropdown class="text-primary-800 dark:text-primary-50" />

            <RouterLink
                v-if="$route.name !== 'login'"
                class="ml-5 flex items-center rounded-lg bg-green-500 px-7 py-1.5 text-sm font-semibold uppercase text-white shadow-lg transition hover:bg-primary-500 hover:text-primary-50"
                to="/login"
            >
                Login
            </RouterLink>
        </div>
    </div>

    <div class="py:8 relative mx-auto flex w-full grow flex-col px-4 lg:px-6 lg:py-6">
        <slot />
    </div>

    <Copyright class="text-primary-50" />
</template>
