<script lang="ts" setup>
import type { ResourceResponse, uuid } from '@/types/general'
import type { Dashboard } from '@/types/dashboard'

import { notify } from '@kyvg/vue3-notification'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useDashboardStore } from '@/stores/dashboard-store'
import { useAuthStore } from '@/stores/auth-store'
import useForm from '@/hooks/use-form'

import MyModal from '@/components/my-components/MyModal.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

interface Props {
    visible: boolean
}

interface Form {
    companyId: uuid
    name: string
    private: boolean
    active: boolean
}

const emit = defineEmits<{
    (e: 'close'): void
}>()

const { t } = useI18n()

const props = defineProps<Props>()

const authStore = useAuthStore()
const dashboardStore = useDashboardStore()
const router = useRouter()
const { data, submit, errors, reset } = useForm<Form>({
    name: '',
    private: false,
    active: true,
    companyId: authStore.companyId,
})

async function creationSuccess(dashboardSlug: string) {
    await dashboardStore.fetchDashboards()
    router.push({ name: 'dashboard.show', params: { slug: dashboardSlug } })
    notify({ type: 'success', text: t('createDashboardSuccess') })
    emit('close')
    reset()
}

function closeModal() {
    emit('close')
    reset()
}

async function onSubmit() {
    try {
        const response = await submit<ResourceResponse<Dashboard>>(
            'POST',
            window.route('company.dashboard.store', {
                company: authStore.companyId,
                userId: authStore.user?.id,
            }),
        )
        if (response) {
            creationSuccess(response.data.slug)
        }
    } catch (e) {
        notify({ type: 'error', text: t('createDashboardError') })
        throw e
    }
}
</script>

<template>
    <MyModal :value="props.visible" @close="closeModal()">
        <template #title>
            {{ t('createDashboard') }}
        </template>
        <MyForm class="flex flex-col space-y-3 mt-2" :errors="errors" @submit.prevent="onSubmit">
            <MyInput
                v-model="data.name"
                name="name"
                :label="`${t('dashboard')} ${t('name').toLowerCase()}`"
                :placeholder="t('name')"
                autofocus
            />
            <MyCheckbox v-model="data.private" :label="t('private')" :checked="data.private" />
            <MyButton scheme="primary" class="ml-auto" v-text="t('create')" />
        </MyForm>
    </MyModal>
</template>
