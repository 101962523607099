<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import NotFoundComponent from '@/pages/NotFoundComponent.vue'
import { useDashboardStore } from '@/stores/dashboard-store'
import { useAuthStore } from '@/stores/auth-store'
import { Dashboard } from '@/types/dashboard'

import DashboardList from '@/components/dashboard/DashboardList.vue'
import DashboardForm from '@/components/dashboard/DashboardForm.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'

const fetching = ref(false)

const route = useRoute()
const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const router = useRouter()
const { t } = useI18n()

function setCurrentDashboard() {
    if (!route.params.slug) {
        // Setting the dashboard from the default dashboard
        if (dashboardStore.defaultDashboardId) {
            const matchedDashboard = dashboardStore.dashboards.find(
                (storedDashboard: Dashboard) =>
                    storedDashboard.id === dashboardStore.defaultDashboardId,
            )

            if (!matchedDashboard) return

            router.replace({
                name: 'dashboard.show',
                params: { slug: matchedDashboard.slug.toString() },
                query: route.query,
            })
        } else if (dashboardStore.dashboards) {
            const firstDashboard = dashboardStore.dashboards[0]
            router.replace({
                name: 'dashboard.show',
                params: { slug: firstDashboard.slug.toString() },
                query: route.query,
            })
        }
        return
    }

    // Setting the dashboard from the slug
    const dashboard = dashboardStore.dashboards.find(
        (dashboard: Dashboard) => dashboard.slug === route.params.slug.toString(),
    )

    if (!dashboard) return

    dashboardStore.setCurrentDashboard(dashboard)
}

async function fetchDashboards() {
    fetching.value = true
    try {
        await dashboardStore.fetchDashboards()
    } catch (e) {
        notify({
            title: t('dashboardsNotFound', { company: authStore.company.name }),
            type: 'error',
        })
        throw e
    } finally {
        fetching.value = false
    }
}

onMounted(async () => {
    await fetchDashboards()
    setCurrentDashboard()
})

onBeforeUnmount(() => {
    dashboardStore.setCurrentDashboard(null)
})

watch(route, setCurrentDashboard)
</script>

<template>
    <DashboardList />

    <DashboardForm
        v-if="dashboardStore.currentDashboard"
        :key="dashboardStore.currentDashboard.id"
    />
    <LoaderWrapper v-else-if="fetching" :visible="fetching" />
    <NotFoundComponent v-else :hide-crumbs="true" :error-message="'noMatchingDashboard'" />
</template>
