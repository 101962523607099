<script lang="ts">
interface Props {
    modelValue: boolean
    deviation?: Deviation
    ticket?: Ticket
    collaboratingCompany?: IncludedCompany
    canFlipResponsibleCompany?: boolean
}
</script>

<script setup lang="ts">
import type { ResourceResponse } from '@/types/general'

import { watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@/hooks/use-form'
import { useAuthStore } from '@/stores/auth-store'
import { DropdownOption } from '@/types/inputs'
import { IncludedCompany } from '@/types/company'
import { Deviation, DeviationState, Ticket } from '@/types/delivery-management'
import { emitter } from '@/utils/mitt'

import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyTextarea from '@/components/my-components/form/MyTextarea.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyModal from '@/components/my-components/MyModal.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'

interface Form {
    state: DeviationState
    note: string
    flipResponsibleCompany: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'saved'): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const { data, submit, errors, reset, loading } = useForm<Form>({
    state: props.deviation?.state ?? props.ticket?.state ?? DeviationState.Open,
    note: '',
    flipResponsibleCompany: false,
})

const title = computed(() => {
    if (props.deviation) {
        return t('changeDeviationStatus')
    } else {
        return t('changeTicketStatus')
    }
})

const states = computed<DropdownOption[]>(() => {
    return [
        { label: t('open'), value: DeviationState.Open },
        { label: t('closed'), value: DeviationState.Closed },
    ]
})

const showChangeResponsiblityBox = computed(() => {
    if (!props.canFlipResponsibleCompany) return false
    if (data.state !== DeviationState.Open) return false
    if (data.state === props.ticket?.state) return false
    return true
})

async function onSubmit() {
    const deviationAssignRoute = window.route('dm.company.deviations.update-state', {
        company: authStore.companyId,
        deviation: props.deviation ?? '',
    })

    const ticketAssignRoute = window.route('dm.company.tickets.update-state', {
        company: authStore.companyId,
        ticket: props.ticket ?? '',
    })

    const response = await submit<ResourceResponse<Deviation | Ticket>>(
        'put',
        props.deviation ? deviationAssignRoute : ticketAssignRoute,
    )

    if (response !== undefined) {
        emit('close')
        emit('saved')
        emitter.emit('fetchAlarms')
    }
}

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue) return
        reset()
    },
)
</script>

<template>
    <MyModal :value="props.modelValue" :max-width="400" @close="emit('close')">
        <LoaderWrapper :visible="loading" class="rounded-xl" />
        <template #title>
            {{ title }}
        </template>
        <MyForm :errors="errors" @submit.prevent="onSubmit">
            <div class="space-y-2">
                <div class="flex flex-col space-y-4">
                    <MySelect
                        v-model="data.state"
                        name="status"
                        group-class="w-full"
                        :label="t('status')"
                        :options="states"
                    />

                    <MyTextarea v-model="data.note" name="note" :label="t('comment')" />
                </div>
            </div>
            <MyCheckbox
                v-if="showChangeResponsiblityBox"
                v-model="data.flipResponsibleCompany"
                :label="t('setResponsibleCompany', { company: props.collaboratingCompany?.name })"
                class="mt-2"
            />
            <div class="mt-4 flex justify-end gap-6">
                <MyButton :disabled="loading" scheme="primary" v-text="t('changeStatus')" />
            </div>
        </MyForm>
    </MyModal>
</template>
