<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

import {
    AdrInformation,
    Colli,
    ColliScanEvent,
    ColliScanEventType,
    ShipmentColli,
} from '@/types/delivery-management'
import { colliScanEventTypes } from '@/utils/delivery-management'
import { datetime } from '@/utils/dates'
import { image } from '@/utils/assets'

export interface Props {
    colli: Colli | ShipmentColli
    showShipmentInformation?: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()

const adrInformation = computed(() => {
    return (props.colli.adrInformation ?? null) as AdrInformation | null
})
const scanEvents = computed(() => {
    return (props.colli.scanEvents ?? []) as ColliScanEvent[]
})

function adrString(): string {
    if (!adrInformation.value) return ''

    return `${adrInformation.value.unNumber}: ${adrInformation.value.name} class ${adrInformation.value.class}`
}
</script>

<template>
    <div class="flex w-full py-2 px-3 rounded-xl bg-white shadow-md dark:bg-dark-400">
        <div class="flex flex-col mr-2 flex-shrink w-[30%]">
            <span
                v-truncate-tooltip="colli.colliNumber"
                class="truncate text-sm font-semibold"
                v-text="colli.colliNumber"
            />

            <div class="flex flex-col">
                <span class="text-xs" v-text="colli.name" />

                <div
                    v-if="colli.minimumTemperature || colli.maximumTemperature"
                    class="flex space-x-1 text-xs"
                >
                    {{ colli.minimumTemperature ?? '-' }}
                    <span class="text-xs mx-0.5">/</span>
                    {{ colli.maximumTemperature ?? '-' }} °C
                </div>

                <div v-if="adrInformation" class="flex space-x-2 mt-1">
                    <img
                        v-for="labelImage in adrInformation.labelImages"
                        :key="labelImage"
                        v-tooltip="adrString()"
                        class="w-[50px] h-[50px]"
                        :src="image(labelImage)"
                        :alt="adrInformation.class"
                    />
                </div>
            </div>
        </div>

        <div class="flex flex-shrink-0 w-[70%] text-xs items-center">
            <div class="flex flex-col items-end w-[31%] pr-2">
                <span class="ml-1 text-primary-300" v-text="t('lxwxh')" />
                <span class="text-sm">
                    {{ colli.length }} x {{ colli.width }} x {{ colli.height }} {{ t('cm') }}
                </span>
            </div>

            <div class="flex flex-col text-right w-[17%]">
                <span class="text-primary-300" v-text="t('volume')" />
                <span class="text-sm" v-text="colli.volume + ' ' + t('cbm')" />
            </div>

            <div class="flex flex-col text-right w-[17%]">
                <span class="text-primary-300" v-text="t('weight')" />
                <span class="text-sm" v-text="colli.weight + ' ' + t('kg')" />
            </div>

            <slot name="suffix" />

            <div v-if="showShipmentInformation" class="flex flex-col text-right w-[17%]">
                <span class="text-primary-300" v-text="t('amount')" />
                <span class="text-sm" v-text="colli.amount" />
            </div>

            <div v-if="showShipmentInformation" class="flex flex-col space-y-1.5 w-[17%] items-end">
                <span class="text-primary-300 text-center" v-text="t('events')" />
                <div v-if="scanEvents.length > 0" class="flex justify-center space-x-1 flex-wrap">
                    <div
                        v-for="event in scanEvents"
                        :key="event.id"
                        v-tooltip="
                            t(colliScanEventTypes[event.type]) + '\n' + datetime(event.createdAt)
                        "
                    >
                        <mdi:human-dolly v-if="event.type === ColliScanEventType.Load" />
                        <mdi:human-dolly
                            v-else-if="event.type === ColliScanEventType.Unload"
                            class="rotate-y-180"
                        />
                        <mdi:truck v-else />
                    </div>
                </div>
                <span v-else class="text-xs text-center">-</span>
            </div>
        </div>
    </div>
</template>
