<script lang="ts" setup>
import type { SetOptional } from 'type-fest'

import { notify } from '@kyvg/vue3-notification'
import { computed, inject, onMounted, Ref, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

import useForm from '@/hooks/use-form'
import { locales } from '@/i18n/i18n'
import { useAuthStore } from '@/stores/auth-store'
import { useCompanyStore } from '@/stores/company-store'
import { DropdownStringOption, MyInputRef, MyPhoneInputRef } from '@/types/inputs'
import { AuthType, Employee, UserType } from '@/types/user'
import { LicenseType } from '@/types/company'
import { image as imageHelper } from '@/utils/assets'
import { userTypeOptions } from '@/utils/employees'
import { cloudflareUpload } from '@/utils/upload'
import { useEmployeeRoleStore } from '@/stores/employee-role-store'
import { type MinimalResource, uuid } from '@/types/general'
import { useLazyLoadedList } from '@/hooks/use-lazy-loaded-list'

import ManageAppRolesModal from '@/components/settings/ManageAppRolesModal.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyFileSelect from '@/components/my-components/MyFileSelect.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyPhoneInput from '@/components/my-components/form/MyPhoneInput.vue'
import MySelect from '@/components/my-components/form/MySelect.vue'
import ManageWebRolesModal from '@/components/settings/ManageWebRolesModal.vue'

export interface Props {
    data: SetOptional<Employee, 'id' | 'appRoleName' | 'webRoleName'>
    authType: AuthType
    companyId?: uuid
}

interface Fields
    extends SetOptional<Employee, 'id' | 'appRoleName' | 'webRoleName'>,
        Record<string, unknown> {
    generatePassword: boolean
    password: string
    passwordConfirmation: string
}

const { t, locale } = useI18n()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const roleStore = useEmployeeRoleStore()

const emit = defineEmits<{ (e: 'created', value?: void): void }>()
const props = defineProps<Props>()

const uploading = ref(false)
const loading = inject<Ref<boolean>>('loading')!
const permissionsToggle = ref(false)
const phoneInput = ref<MyPhoneInputRef>()
const usernameInput = ref<MyInputRef>()
const userTypes = userTypeOptions(t)
const manageAppRolesModalOpen = ref(false)
const manageWebRolesModalOpen = ref(false)
const locations = useLazyLoadedList<DropdownStringOption>(fetchLocations)
const {
    data: form,
    errors,
    submit,
} = useForm<Fields>({
    id: props.data.id,
    phoneNumber: props.data.phoneNumber,
    username: props.data.username,
    name: props.data.name,
    profileImage: props.data.profileImage,
    email: props.data.email,
    userType: props.data.userType,
    webportalEnabled: props.data.webportalEnabled,
    appEnabled: props.data.appEnabled,
    permissions: { readonly: false },
    twoFactorEnabled: props.data.twoFactorEnabled,
    locale: locale.value,
    generatePassword: true,
    password: '',
    passwordConfirmation: '',
    locations: [],
    settlementTemplateId: null,
    appRoleId: null,
    webRoleId: null,
    employeeNumber: '',
    salaryNumber: null,
})
const localeOptions = computed(() => {
    return Object.entries(locales).map(([key, value]) => ({
        value: key,
        label: value.name,
    }))
})

async function handleImageUpload(file: File) {
    uploading.value = true
    try {
        form.profileImage = await cloudflareUpload(file)
        uploading.value = false
    } catch (e) {
        notify({ title: t('unknownError'), type: 'error' })
    }
}

async function fetchLocations(): Promise<DropdownStringOption[]> {
    if (!props.companyId || props.companyId === authStore.companyId) {
        return companyStore.locations.map(({ id, name }) => ({ value: id, label: name }))
    }

    const response = await axios.get<MinimalResource[]>(
        window.route('minimal.companies.locations', {
            company: props.companyId,
        }),
    )

    return response.data.map((location) => ({ value: location.id, label: location.name }))
}

async function onSubmit() {
    loading.value = true
    let actingCompanyId: string | undefined = undefined
    if (props.companyId && props.companyId !== authStore.companyId) {
        actingCompanyId = authStore.companyId
    }

    const response = await submit(
        'post',
        window.route('company.employees.store', {
            company: props.companyId || authStore.companyId,
            actingCompanyId,
        }),
    )
    loading.value = false
    if (response) {
        emit('created')
    }
}

onMounted(() => {
    if (props.authType === AuthType.PhoneNumber) {
        usernameInput.value!.input.focus()
    } else {
        phoneInput.value!.focus()
    }
    roleStore.fetchAppRoles()
    roleStore.fetchWebRoles()
    companyStore.fetchSettlementTemplates()
})
</script>

<template>
    <MyForm class="mt-2 space-y-2" :errors="errors" @submit.prevent="onSubmit">
        <div class="flex justify-center">
            <LoaderWrapper :visible="uploading" class="rounded-xl" />

            <MyFileSelect
                class="rounded-full"
                :class="{ 'h-20 min-w-[250px]': !form.profileImage }"
                type="image/*"
                @selected="handleImageUpload"
            >
                <p
                    class="absolute z-10 text-xs font-semibold dark:text-gray-300"
                    :class="{ 'mt-10': form.profileImage }"
                    v-text="t('dragAndDropImage')"
                />

                <img
                    v-if="form.profileImage"
                    class="m-2 block h-20 w-20 rounded-full opacity-100 ring-2 ring-white hover:opacity-60"
                    :src="imageHelper(form.profileImage)"
                    alt="Avatar"
                />
            </MyFileSelect>
        </div>

        <MyInput
            ref="usernameInput"
            v-model="form.username"
            name="username"
            :label="t('username')"
            :disabled="props.authType === AuthType.Username"
            type="text"
            :placeholder="t('username')"
        />

        <MyPhoneInput
            ref="phoneInput"
            v-model="form.phoneNumber"
            name="phoneNumber"
            :label="t('phoneNumber')"
            :disabled="props.authType === AuthType.PhoneNumber"
            type="text"
            :placeholder="t('phoneNumber')"
        />

        <MyCheckbox
            v-model="form.twoFactorEnabled"
            name="twoFactorEnabled"
            :label="t('twoFactorEnabled')"
        />

        <!-- It's not possible to add web users for other companies so we don't need to show the toggles -->
        <div v-if="!props.companyId" class="space-x-3 pt-2">
            <MyCheckbox
                v-model="form.webportalEnabled"
                name="webportalEnabled"
                :label="t('webportalEnabled')"
            />
            <MyCheckbox v-model="form.appEnabled" name="appEnabled" :label="t('appEnabled')" />
        </div>

        <MyInput
            v-model="form.name"
            name="name"
            :label="t('name')"
            type="text"
            :placeholder="t('name')"
        />

        <MyInput
            v-model="form.email"
            name="email"
            :label="t('email')"
            type="text"
            :placeholder="t('email')"
        />

        <MySelect
            v-if="form.webportalEnabled"
            v-model="form.locale"
            :label="t('webportalLanguage')"
            :options="localeOptions"
        />

        <MySelect
            v-if="authStore.hasLicense(LicenseType.AgentLocationFiltering)"
            v-model="form.locations"
            :label="t('locations')"
            :placeholder="t('allLocations')"
            multiple
            searchable
            :options="locations.items.value"
            :loading="locations.fetching.value"
            @focus="locations.fetch()"
        />

        <MyInput
            v-model="form.employeeNumber"
            name="employeeNumber"
            :label="t('employeeNumber')"
            type="text"
            :placeholder="t('employeeNumber')"
        />

        <!-- It's not possible to set the salary number for other companies so we don't need to show the input -->
        <MyInput
            v-if="!props.companyId"
            v-model="form.salaryNumber"
            name="salaryNumber"
            :label="t('salaryNumber')"
            type="text"
            :placeholder="t('salaryNumber')"
        />

        <div v-if="form.appEnabled">
            <h5 class="mt-5 mb-2 text-base" v-text="t('appPreferences')" />

            <hr class="mb-2" />

            <div class="space-y-2">
                <MySelect
                    v-model="form.userType"
                    :options="userTypes"
                    name="userType"
                    :label="t('userType')"
                />

                <div
                    v-if="
                        data.userType === UserType.Driver &&
                        authStore.hasLicense(LicenseType.DamageReportModule) &&
                        !props.companyId
                    "
                    class="flex items-end space-x-2"
                >
                    <MySelect
                        v-model="form.appRoleId"
                        :options="roleStore.appRoleOptions"
                        :placeholder="t('standard')"
                        group-class="w-full"
                        name="roleId"
                        :label="t('userRole')"
                        clear-button
                    />

                    <MyButton
                        v-tooltip="t('manageUserRoles')"
                        icon
                        type="button"
                        @click="manageAppRolesModalOpen = true"
                    >
                        <mdi:account-group />
                    </MyButton>
                </div>

                <MySelect
                    v-if="!authStore.hasLicense(LicenseType.AgentLocationFiltering)"
                    v-model="form.locations"
                    :label="t('locations')"
                    :placeholder="t('allLocations')"
                    multiple
                    searchable
                    :options="locations.items.value"
                    :loading="locations.fetching.value"
                    @focus="locations.fetch()"
                />

                <MySelect
                    v-if="authStore.hasLicense(LicenseType.DriverReportModule) && !props.companyId"
                    v-model="form.settlementTemplateId"
                    :options="companyStore.settlementTemplateOptions"
                    :placeholder="t('flatRate')"
                    group-class="w-full"
                    name="settlementTemplateId"
                    :label="`${t('driverReports')} ${t('settlementTemplate')}`"
                    clear-button
                    :loading="companyStore.loadingSettlementTemplates"
                />
            </div>
        </div>

        <MyCheckbox
            v-if="!form.id"
            v-model="form.generatePassword"
            class="pt-2"
            :label="t('generatePassword')"
        />

        <template v-if="!form.generatePassword">
            <MyInput
                v-model="form.password"
                name="password"
                :label="t('password')"
                type="password"
                :placeholder="t('password')"
            />
            <MyInput
                v-model="form.passwordConfirmation"
                :label="t('confirmPassword')"
                type="password"
            />
        </template>

        <div v-if="form.webportalEnabled" class="flex flex-col">
            <div
                class="mt-2 flex cursor-pointer justify-between"
                @click="permissionsToggle = !permissionsToggle"
            >
                <h5 class="mb-2 select-none text-base" v-text="t('webportalPermissions')" />

                <mdi:chevron-down
                    class="ml-1 transition duration-300"
                    :class="{ 'rotate-180': permissionsToggle }"
                />
            </div>

            <hr class="mb-2" />

            <div v-if="permissionsToggle" class="mb-2 flex items-end space-x-2">
                <MySelect
                    v-model="form.webRoleId"
                    :options="roleStore.webRoleOptions"
                    group-class="w-full"
                    name="webRoleId"
                    :label="t('role')"
                    clear-button
                    :loading="roleStore.fetchingWebRoles"
                />

                <MyButton
                    v-tooltip="t('manageUserRoles')"
                    icon
                    type="button"
                    @click="manageWebRolesModalOpen = true"
                >
                    <mdi:account-group />
                </MyButton>
            </div>
        </div>

        <MyButton :disabled="loading" class="mt-3 w-full" scheme="primary">
            {{ t('createEmployee') }}
        </MyButton>

        <ManageAppRolesModal v-model="manageAppRolesModalOpen" />
        <ManageWebRolesModal v-model="manageWebRolesModalOpen" />
    </MyForm>
</template>
