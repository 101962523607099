<script lang="ts" setup>
import { DashboardWidget, WidgetType } from '@/types/dashboard'

import MyWidget from '@/components/dashboard/widgets/MyWidget.vue'

export interface Props {
    widget: DashboardWidget<WidgetType.Example>
    preview?: boolean
}

const props = defineProps<Props>()
</script>

<template>
    <MyWidget :widget="props.widget" :preview="props.preview" />
</template>
