import { defineStore } from 'pinia'
import axios from 'axios'

import { useAuthStore } from '@/stores/auth-store'
import { ResourceResponse, uuid } from '@/types/general'
import { Dashboard, DashboardWidget } from '@/types/dashboard'

interface DashboardStoreState {
    dashboards: Dashboard[]
    currentDashboard: Dashboard | null
    defaultDashboardId: uuid | null
}

interface DashboardResponse<Model> extends ResourceResponse<Model> {
    dashboards: Dashboard[]
    defaultDashboardId: uuid
}

export const useDashboardStore = defineStore('DashboardStore', {
    state: (): DashboardStoreState => ({
        dashboards: [],
        currentDashboard: window.dashboard,
        defaultDashboardId: '',
    }),
    getters: {
        updatedDashboard: (state) => {
            return (dashboardId: uuid) =>
                state.dashboards.find((dashboard: Dashboard) => dashboard.id === dashboardId)
        },
    },
    actions: {
        async fetchDashboards() {
            const authStore = useAuthStore()
            const response = await axios.get<DashboardResponse<Dashboard[]>>(
                window.route('company.dashboard.index', {
                    company: authStore.companyId,
                }),
            )
            this.updateDashboards(response.data.dashboards)
            this.setDefaultDashboard(response.data.defaultDashboardId)
        },

        async generateShareToken() {
            const authStore = useAuthStore()
            const response = await axios.put<ResourceResponse<Dashboard>>(
                window.route('company.dashboard.share', {
                    company: authStore.companyId,
                    dashboard: this.currentDashboard,
                }),
            )
            this.currentDashboard!.shareToken = response.data.data.shareToken
        },

        updateDashboards(dashboards: Dashboard[]) {
            this.dashboards = dashboards
        },

        setCurrentDashboard(dashboard: Dashboard | null) {
            this.currentDashboard = JSON.parse(JSON.stringify(dashboard))
        },

        reloadCurrentDashboard() {
            const newDashboard = this.dashboards.find((d) => d.id === this.currentDashboard?.id)

            this.setCurrentDashboard(newDashboard!)
        },

        setDefaultDashboard(dashboardId: uuid) {
            this.defaultDashboardId = dashboardId
        },

        setDashboardEditStatus(state: boolean) {
            if (!this.currentDashboard) return
            this.currentDashboard.isEditing = state
        },

        emptyCurrentDashboard() {
            this.dashboards = this.dashboards.filter(
                (dashboard) => dashboard.id !== this.currentDashboard?.id,
            )
            this.currentDashboard = null
        },

        updateWidgetOptions<T extends DashboardWidget>(widget: T, options: Partial<T['options']>) {
            // We'll find the widget in the store to ensure we have the latest changes
            const existingWidget = this.currentDashboard?.widgets.find((w) => w.id === widget.id)

            if (!existingWidget) return

            existingWidget.options = { ...existingWidget.options, ...options }
        },

        deleteWidget(widgetId: uuid) {
            const widgetIndex = this.currentDashboard?.widgets.findIndex(
                (widget) => widget.id === widgetId,
            )

            if (widgetIndex! === -1) return

            this.currentDashboard?.widgets.splice(widgetIndex!, 1)
        },
    },
})
