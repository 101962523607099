<script setup lang="ts">
import { onMounted, ref } from 'vue'
import axios, { AxiosRequestConfig } from 'axios'
import { useI18n } from 'vue-i18n'
import { debouncedWatch } from '@vueuse/core'
import { useRoute } from 'vue-router'

import { PaginatedResponse } from '@/types/general'
import { useAuthStore } from '@/stores/auth-store'
import { type PaginationData } from '@/types/general'
import { datetime } from '@/utils/dates'
import { MinimalTour } from '@/types/delivery-management'

import TourModal from '@/components/delivery-management/tour-modal/TourModal.vue'
import PaginationFooter from '@/components/PaginationFooter.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'

const emit = defineEmits<{
    (e: 'tourSelected', value: MinimalTour): void
    (e: 'update:open', value: boolean): void
}>()

const authStore = useAuthStore()
const { t } = useI18n()
const route = useRoute()

const paginationData = ref<PaginationData>({
    from: 1,
    to: 1,
    perPage: 5,
    lastPage: 1,
    currentPage: Number(route.query.page ?? 1),
    total: 0,
})
const tours = ref<MinimalTour[]>([])
const searchQuery = ref('')
const loading = ref(false)
const selectedTourId = ref<string>()
const tourModalOpen = ref(false)

async function fetchTours(page?: number) {
    loading.value = true

    const params: AxiosRequestConfig['params'] = {
        filter: { 'has-shipper': true },
        query: searchQuery.value,
        page: page ?? paginationData.value.currentPage,
        'per-page': 5,
    }
    const response = await axios.get<PaginatedResponse<MinimalTour>>(
        window.route('dm.company.tours.index', {
            company: authStore.companyId,
        }),
        { params: params },
    )

    tours.value = response.data.data

    paginationData.value = {
        from: response.data.meta.from,
        to: response.data.meta.to,
        perPage: response.data.meta.per_page,
        lastPage: response.data.meta.last_page,
        currentPage: response.data.meta.current_page,
        total: response.data.meta.total,
    }

    loading.value = false
}

async function selectTour(tour: MinimalTour) {
    emit('tourSelected', tour)
}

function showTour(tourId: string) {
    selectedTourId.value = tourId
    tourModalOpen.value = true
}

debouncedWatch(
    () => searchQuery.value,
    () => {
        fetchTours()
    },
    { debounce: 500 },
)

onMounted(() => {
    fetchTours()
})
</script>

<template>
    <div class="flex min-h-full w-full flex-row">
        <LoaderWrapper :visible="loading" class="rounded-xl" />

        <div class="flex w-full flex-col">
            <div class="flex items-center space-x-1 mb-4">
                <span
                    class="dark:text-primary-50 cursor-pointer"
                    @click="emit('update:open', false)"
                >
                    <mdi:chevron-left class="w-5 h-5" />
                </span>
                <span class="dark:text-primary-50">{{ t('availableTours') }}</span>
            </div>
            <div
                class="flex w-full flex-col justify-start px-4 rounded-xl border border-gray-300 dark:border-dark-500"
            >
                <div class="w-full self-center mt-3">
                    <MyInput
                        v-model="searchQuery"
                        :background="'bg-primary-50 focus:bg-white dark:bg-dark-500 dark:focus:bg-dark-700'"
                        :placeholder="t('search')"
                        class="h-10 w-full border-r-0 border-l-0 pt-1.5 pb-1.5"
                        type="search"
                    >
                        <template #icon>
                            <mdi:magnify />
                        </template>
                        <template #button>
                            <button type="button" class="hover:opacity-60" @click="fetchTours()">
                                <mdi:refresh class="absolute top-0 bottom-0 right-2 m-auto h-5" />
                            </button>
                        </template>
                    </MyInput>
                </div>

                <div class="mt-4 flex flex-col space-y-3">
                    <div
                        v-for="tour in tours"
                        :key="tour.id"
                        class="flex justify-between space-x-3 w-full rounded-xl bg-white p-3 shadow-md dark:bg-dark-400"
                    >
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-sm dark:text-primary-50 font-semibold"
                                v-text="t('tour')"
                            />
                            <div v-if="tour.name" class="flex items-center">
                                <span class="text-xs dark:text-primary-50 break-all mr-1">
                                    {{ tour.name }}
                                </span>
                                <mdi:eye
                                    class="cursor-pointer hover:text-primary-400 dark:hover:text-primary-300"
                                    @click="showTour(tour.id)"
                                />
                            </div>
                            <span v-else class="text-xs dark:text-primary-50" v-text="'-'" />
                            <span class="text-xs">{{ datetime(tour.plannedAt) }}</span>
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-sm dark:text-primary-50 font-semibold"
                                v-text="t('company')"
                            />
                            <div v-if="tour.company" class="flex flex-col">
                                <span class="text-xs dark:text-primary-50">{{
                                    tour.company.name
                                }}</span>
                            </div>
                        </div>
                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-sm dark:text-primary-50 font-semibold"
                                v-text="t('shipper')"
                            />
                            <div v-if="tour.shipperCompany" class="flex flex-col">
                                <span class="text-xs dark:text-primary-50">{{
                                    tour.shipperCompany.name
                                }}</span>
                            </div>
                            <span
                                v-else
                                class="text-xs dark:text-primary-50"
                                v-text="t('noShipperForTour')"
                            />
                        </div>

                        <div class="flex w-1/2 flex-col">
                            <span
                                class="text-sm dark:text-primary-50 font-semibold"
                                v-text="t('haulier')"
                            />
                            <div v-if="tour.haulierCompany" class="flex flex-col">
                                <span class="text-xs dark:text-primary-50">{{
                                    tour.haulierCompany.name
                                }}</span>
                            </div>
                            <span
                                v-else
                                class="text-xs dark:text-primary-50"
                                v-text="t('noHaulierForTour')"
                            />
                        </div>

                        <div class="flex flex-shrink-0 items-center justify-end">
                            <MyButton
                                scheme="primary"
                                type="button"
                                size="small"
                                @click="selectTour(tour)"
                            >
                                {{ t('select') }}
                            </MyButton>
                        </div>
                    </div>

                    <div v-if="tours.length <= 0 && !loading" class="flex justify-center">
                        <span class="dark:text-primary-300">{{ t('noAvailableTours') }}</span>
                    </div>
                    <PaginationFooter
                        class="border-t border-primary-200 dark:border-dark-500"
                        hide-per-page
                        :pagination-data="paginationData"
                        :refetch="(params) => fetchTours(params?.page ?? 1)"
                        :row-count="tours.length ?? 0"
                    />
                </div>
            </div>
        </div>

        <TourModal
            v-if="selectedTourId"
            :id="selectedTourId"
            v-model:open="tourModalOpen"
            @update:open="selectedTourId = undefined"
        />
    </div>
</template>
