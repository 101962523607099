<script lang="ts" setup>
import 'gridstack/dist/gridstack.min.css'
import { GridStackWidget } from 'gridstack'
import { nextTick, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'
import { v4 as uuidv4 } from 'uuid'

import useForm from '@/hooks/use-form'
import { ResourceResponse, uuid } from '@/types/general'
import { Dashboard, DashboardGridRef, DashboardWidget } from '@/types/dashboard'
import { useConfirm } from '@/hooks/use-confirm'
import { useDashboardStore } from '@/stores/dashboard-store'
import { useAuthStore } from '@/stores/auth-store'
import { MyButtonScheme } from '@/types/layout/my-button'
import { useMittListener } from '@/hooks/use-mitt-listener'

import WidgetBrowser from '@/components/dashboard/WidgetBrowser.vue'
import LoaderWrapper from '@/components/loaders/LoaderWrapper.vue'
import MyInput from '@/components/my-components/form/MyInput.vue'
import MyForm from '@/components/my-components/form/MyForm.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyCheckbox from '@/components/my-components/form/MyCheckbox.vue'
import DashboardGrid from '@/components/dashboard/DashboardGrid.vue'
import ShareDashboardModal from '@/components/dashboard/ShareDashboardModal.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const confirm = useConfirm()
const authStore = useAuthStore()
const dashboardStore = useDashboardStore()

const { data, submit, errors, reset } = useForm({})

const dashboardGrid = ref<DashboardGridRef>()
const loading = ref(false)
const shareDashboardModalOpen = ref(false)
const widgetBrowserOpen = ref(false)

async function resetFields() {
    dashboardStore.reloadCurrentDashboard()

    await nextTick()
    dashboardGrid.value?.refreshGrid()
    dashboardStore.setDashboardEditStatus(false)
}

function onGridChange(changeItems: GridStackWidget[]) {
    if (!changeItems) return
    changeItems.forEach((item) => {
        const widget = dashboardStore.currentDashboard!.widgets.find(
            (widget) => widget.id == item.id,
        )
        if (!widget) {
            return
        }

        widget.x = item.x ?? 0
        widget.y = item.y ?? 0
        widget.height = item.h ?? 1
        widget.width = item.w ?? 1
    })
}

function updateCurrentDashboard(dashboardId: uuid, dashboardSlug?: string) {
    dashboardStore.fetchDashboards()
    dashboardStore.updatedDashboard(dashboardId)
    router.push({ name: 'dashboard.show', params: { slug: dashboardSlug } })
}

async function save() {
    const response = await submit<ResourceResponse<Dashboard>>(
        'PUT',
        window.route('company.dashboard.update', {
            company: authStore.companyId,
            dashboard: dashboardStore.currentDashboard,
            data: data,
        }),
        {
            data: {
                name: dashboardStore.currentDashboard!.name,
                active: dashboardStore.currentDashboard!.active,
                private: dashboardStore.currentDashboard!.private,
                widgets: dashboardStore.currentDashboard!.widgets,
            },
        },
    )
    if (!response) return
    dashboardStore.setDashboardEditStatus(false)
    updateCurrentDashboard(dashboardStore.currentDashboard!.id ?? '', response.data.slug)
}

async function deleteDashboard() {
    try {
        loading.value = true

        await submit(
            'DELETE',
            window.route('company.dashboard.destroy', {
                company: authStore.companyId,
                dashboard: dashboardStore.currentDashboard,
            }),
        )

        dashboardStore.emptyCurrentDashboard()
        reset()
        dashboardStore.setDashboardEditStatus(false)

        router.replace({
            name: 'dashboard',
            query: route.query,
        })
    } finally {
        loading.value = false
    }
}

async function deleteConfirm() {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('dashboard') }),
            t('deleteDashboardPrompt'),
            {
                confirmText: t('confirm'),
                cancelText: t('cancel'),
                confirmButtonScheme: MyButtonScheme.Danger,
            },
        )
        await deleteDashboard()
    } catch (e) {
        if (!e) return
        notify({ type: 'error', text: t('deleteDashboardError') })
        throw e
    }
}

function isDefaultDashboard(dashboardId: uuid) {
    return dashboardId === dashboardStore.defaultDashboardId
}

async function setDefaultDashboard(dashboard: Dashboard) {
    loading.value = true
    try {
        const response = await axios.put<uuid>(
            window.route('company.dashboard.favorite', {
                company: authStore.companyId,
                dashboard: dashboard,
            }),
        )
        if (response) {
            dashboardStore.setDefaultDashboard(response.data)
        }
    } finally {
        loading.value = false
    }
}

async function addWidget(widget: DashboardWidget) {
    if (!dashboardGrid.value) return
    widget.id = uuidv4()
    const newWidget = dashboardGrid.value!.addWidget(widget)
    dashboardStore.currentDashboard?.widgets.push(newWidget)
}

async function deleteWidget(widget: DashboardWidget) {
    if (!dashboardGrid.value) return

    dashboardStore.deleteWidget(widget.id)
    dashboardGrid.value!.refreshGrid()
}

onMounted(() => {
    if (!dashboardStore.currentDashboard) return
})

useMittListener('deleteWidget', deleteWidget)
</script>

<template>
    <div v-if="dashboardStore.currentDashboard" class="flex flex-col">
        <LoaderWrapper :visible="loading" />
        <div class="flex ml-auto mt-4 space-x-3">
            <MyButton
                :scheme="
                    isDefaultDashboard(dashboardStore.currentDashboard.id) ? 'success' : 'primary'
                "
                :plain="!isDefaultDashboard(dashboardStore.currentDashboard.id)"
                @click.stop="setDefaultDashboard(dashboardStore.currentDashboard)"
            >
                <mdi:star
                    class="text-base mr-2"
                    :class="{
                        '!text-yellow-400': isDefaultDashboard(dashboardStore.currentDashboard.id),
                    }"
                />
                {{
                    isDefaultDashboard(dashboardStore.currentDashboard.id)
                        ? t('unFavorite')
                        : t('favorite')
                }}
            </MyButton>
            <MyButton
                scheme="primary"
                plain
                @click="shareDashboardModalOpen = !shareDashboardModalOpen"
            >
                <mdi:share class="mr-2" />
                {{ t('share') }}
            </MyButton>
            <MyButton
                v-if="!dashboardStore.currentDashboard.isEditing"
                plain
                scheme="primary"
                @click="dashboardStore.setDashboardEditStatus(true)"
            >
                <mdi:pencil class="mr-2" />
                {{ t('edit') }}
            </MyButton>
        </div>
        <div
            class="flex flex-col shadow-xl bg-primary-50 dark:bg-dark-500 rounded-md p-3 mb-3 mt-6 ml-auto w-full"
            :class="{
                '!bg-transparent !shadow-none !p-0': !dashboardStore.currentDashboard.isEditing,
            }"
        >
            <MyForm
                v-if="dashboardStore.currentDashboard.isEditing"
                class="flex flex-col"
                :errors="errors"
                @submit.prevent="save()"
            >
                <div class="flex w-full">
                    <div class="flex flex-col space-y-2">
                        <MyInput
                            v-model="dashboardStore.currentDashboard.name"
                            name="name"
                            :label="t('name')"
                        />
                        <MyCheckbox
                            v-model="dashboardStore.currentDashboard.private"
                            :label="t('private')"
                            :checked="dashboardStore.currentDashboard.private"
                        />
                        <MyCheckbox
                            v-model="dashboardStore.currentDashboard.active"
                            :label="t('active')"
                            :checked="dashboardStore.currentDashboard.active"
                        />
                    </div>
                    <MyButton
                        size="small"
                        class="w-fit text-red-600 ml-auto"
                        @click.prevent="deleteConfirm()"
                    >
                        <mdi:trash-can class="mr-1" />
                        {{ t('deleteDashboard') }}
                    </MyButton>
                </div>

                <div class="flex flex-col items-center justify-between">
                    <div class="ml-auto space-x-3">
                        <MyButton
                            scheme="light"
                            type="button"
                            @click.prevent="resetFields()"
                            v-text="t('cancel')"
                        />
                        <MyButton scheme="primary" type="submit" v-text="t('save')" />
                    </div>
                </div>
            </MyForm>
        </div>
        <MyButton
            v-if="dashboardStore.currentDashboard.isEditing"
            scheme="primary"
            class="w-fit mb-3 ml-auto"
            @click="widgetBrowserOpen = true"
        >
            <mdi-plus-thick class="mr-1" />
            {{ t('addWidgets') }}
        </MyButton>
        <div
            v-if="
                dashboardStore.currentDashboard.widgets.length === 0 &&
                !dashboardStore.currentDashboard.isEditing
            "
            class="text-center font-semibold text-xl mt-10 dark:text-primary-50 text-primary-700"
        >
            {{ t('noWidgets', { entity: dashboardStore.currentDashboard.name }) }}
        </div>
        <div
            :class="{
                'border-dashed border-primary-300 dark:border-dark-300 border-2 rounded-md':
                    dashboardStore.currentDashboard.isEditing,
            }"
        >
            <DashboardGrid
                ref="dashboardGrid"
                :widgets="dashboardStore.currentDashboard.widgets"
                @change="onGridChange"
            />
        </div>
        <ShareDashboardModal
            v-model="shareDashboardModalOpen"
            @close="shareDashboardModalOpen = false"
        />
    </div>
    <WidgetBrowser v-model="widgetBrowserOpen" @add-widget="addWidget" />
</template>
