<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { computed, ref, watch } from 'vue'
import dayjs from 'dayjs'
import Datepicker, { DatePickerInstance } from '@vuepic/vue-datepicker'

import { useAuthStore } from '@/stores/auth-store'
import { uuid, PaginatedResponse } from '@/types/general'
import { usePaginatedTable } from '@/hooks/table/use-paginated-table'
import { Colli } from '@/types/delivery-management'
import { useConfirm } from '@/hooks/use-confirm'
import { MyButtonScheme } from '@/types/layout/my-button'
import { GetDataParameters } from '@/types/table'
import { datetime } from '@/utils/dates'
import { usePresetRangeList } from '@/hooks/use-preset-range-list'

import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import CrumbsAndActions from '@/components/layout/CrumbsAndActions.vue'
import MyPanel from '@/components/my-components/MyPanel.vue'
import MyButton from '@/components/my-components/MyButton.vue'
import MyTable from '@/components/table/MyTable.vue'
import MyTableColumn from '@/components/table/MyTableColumn.vue'
import ActionMenuItem from '@/components/table/ActionMenuItem.vue'
import ManageColliModal from '@/components/delivery-management/ManageColliModal.vue'

interface FetchCollisParams extends Record<string, unknown>, GetDataParameters {
    filter: {
        'shipment-id': uuid | undefined
        'start-date': string | undefined
        'end-date': string | undefined
    }
}

const { t } = useI18n()
const authStore = useAuthStore()
const confirm = useConfirm()
const presetRanges = usePresetRangeList()

const { data, params, paginationData, loading, error, refetch } = usePaginatedTable<
    Colli,
    FetchCollisParams
>(
    async (params, abortController) => {
        const response = await axios.get<PaginatedResponse<Colli>>(
            window.route('dm.company.collis.index', {
                company: authStore.companyId,
            }),
            { params: params, signal: abortController.signal },
        )

        return response.data
    },
    {
        filter: {
            'shipment-id': undefined,
            'start-date': dayjs().subtract(6, 'days').startOf('day').toDate().toISOString(),
            'end-date': dayjs().endOf('day').toDate().toISOString(),
        },
    },
)

const colliModalOpen = ref(false)
const selectedColliId = ref<uuid>()
const datepicker = ref<DatePickerInstance>(null)
const date = ref<Date[]>(
    params.value.filter['start-date'] && params.value.filter['end-date']
        ? [
              dayjs(params.value.filter['start-date']).toDate(),
              dayjs(params.value.filter['end-date']).toDate(),
          ]
        : [],
)

const selectedColli = computed(() => {
    if (!selectedColliId.value) return undefined
    return data.value!.find(({ id }) => id === selectedColliId.value)
})

function setFilter(type: string | number | null, filterName: string) {
    params.value.filter[filterName] = type
    params.value.page = 1
}

function selectColli(id: string) {
    selectedColliId.value = id
}

function updateColli(colli: Colli) {
    selectColli(colli.id)
    colliModalOpen.value = true
}

async function colliModalClosed() {
    colliModalOpen.value = false
    selectedColliId.value = undefined
}

async function deleteColli(colli: Colli) {
    try {
        await confirm(
            t('deleteEntityTitle', { entity: t('tour') }),
            t('deleteEntityDescription', { entity: t('tour') }),
            {
                confirmText: t('yes'),
                cancelText: t('no'),
                confirmButtonScheme: MyButtonScheme.Warning,
            },
        )
    } catch {
        return
    }
    await axios.delete(
        window.route('dm.company.collis.destroy', {
            company: authStore.companyId,
            colli: colli.id,
        }),
    )
    await refetch()
}

function clearDates() {
    datepicker.value?.closeMenu()
    date.value = []
    delete params.value.filter['start-date']
    delete params.value.filter['end-date']
}

watch(date, () => {
    if (!date.value[0] || !date.value[1]) return
    setFilter(dayjs(date.value[0]).startOf('day').toISOString(), 'start-date')
    setFilter(dayjs(date.value[1]).endOf('day').toISOString(), 'end-date')
})
</script>

<template>
    <CrumbsAndActions>
        <Breadcrumb :to="{ name: 'dm' }" v-text="t('deliveryManagement')" />
        <Breadcrumb current v-text="t('collis')" />

        <template #actions>
            <MyButton plain scheme="primary" size="small" @click="colliModalOpen = true">
                <mdi:plus-thick class="mr-1" />
                {{ t('addColli') }}
            </MyButton>
        </template>
    </CrumbsAndActions>

    <RouterView />

    <MyPanel v-if="authStore.companyId" shadow>
        <MyTable
            table-id="dm-collis"
            :rows="data"
            :pagination-data="paginationData"
            :loading="loading"
            :error="error"
            :get-data="refetch"
            :entity-name="t('collis')"
            :has-time-range="!!params.filter['start-date']"
        >
            <template #filters>
                <Datepicker
                    ref="datepicker"
                    v-model="date"
                    input-class-name="w-64"
                    range
                    :enable-time-picker="false"
                    auto-apply
                    :teleport="true"
                    :clearable="false"
                    :preset-dates="presetRanges"
                    close-on-scroll
                    position="left"
                    format="yyyy-MM-dd"
                    :transitions="false"
                    :placeholder="t('allTime')"
                    :config="{ closeOnAutoApply: true }"
                >
                    <template #allTime>
                        <button
                            class="dp__btn dp--preset-range"
                            @click="clearDates()"
                            v-text="t('allTime')"
                        />
                    </template>
                </Datepicker>
            </template>

            <template #actionMenu="{ row }">
                <ActionMenuItem @click="updateColli(row)">
                    <span class="mr-2"><mdi:pencil /></span>
                    {{ t('edit') }}
                </ActionMenuItem>

                <ActionMenuItem @click="deleteColli(row)">
                    <span class="mr-2"><mdi:trash-can /></span>
                    {{ t('remove') }}
                </ActionMenuItem>
            </template>
            <MyTableColumn :name="t('name')" property="name" />
            <MyTableColumn :name="t('colliNumber')" property="colliNumber" />
            <MyTableColumn :name="t('shipment')" property="shipmentNumber" :sortable="false" />
            <template #shipmentNumber="{ row }">
                <div class="flex">
                    <span class="w-full truncate" v-text="row.shipment?.shipmentNumber" />

                    <RouterLink
                        v-if="row.shipment?.id"
                        :to="{
                            name: 'dm.shipments.show',
                            params: { shipmentId: row.shipment.id },
                        }"
                        class="text-md font-semibold text-primary-500 hover:text-primary-400 dark:text-primary-100"
                    >
                        <mdi:document
                            class="ml-2 text-blue-500 cursor-pointer hover:text-blue-700"
                            style="width: 20px"
                        />
                    </RouterLink>
                </div>
            </template>
            <MyTableColumn :name="t('tour')" property="tour" :sortable="false" />
            <template #tour="{ row }">
                <div class="flex">
                    <span class="w-full truncate" v-text="row.tour?.name" />

                    <RouterLink
                        v-if="row.tour?.id"
                        :to="{
                            name: 'dm.tours.show',
                            params: { tourId: row.tour.id },
                        }"
                        class="text-md font-semibold text-primary-500 hover:text-primary-400 dark:text-primary-100"
                    >
                        <mdi:truck-delivery
                            class="ml-2 text-blue-500 cursor-pointer hover:text-blue-700"
                            style="width: 20px"
                        />
                    </RouterLink>
                </div>
            </template>
            <MyTableColumn :name="t('volume')" property="volume" />
            <template #volume="{ row }"> {{ row.volume }} {{ t('cbm') }} </template>

            <MyTableColumn :name="t('dimensions')" property="dimensions" />
            <template #dimensions="{ row }">
                <span v-tooltip="t('lengthWidthHeight')">
                    {{ row.length }} x {{ row.width }} x {{ row.height }} {{ t('cm') }}
                </span>
            </template>
            <MyTableColumn :name="t('weight')" property="weight" />
            <template #weight="{ row }"> {{ row.weight }} {{ t('kg') }} </template>
            <MyTableColumn :name="t('description')" property="description" />

            <MyTableColumn :name="t('pickupDate')" property="pickupDate" />
            <template #pickupDate="{ row }">
                {{ row.pickupDate ? datetime(row.pickupDate) : null }}
            </template>
            <MyTableColumn :name="t('deliveryDate')" property="deliveryDate" />
            <template #deliveryDate="{ row }">
                {{ row.deliveryDate ? datetime(row.deliveryDate) : null }}
            </template>

            <MyTableColumn :name="t('tickets')" property="tickets" :sortable="false" />
            <template #tickets="{ row }">
                <div class="flex space-x-1">
                    <RouterLink
                        v-for="ticketId in row.tickets"
                        :key="ticketId"
                        v-tooltip="t('showTicket')"
                        :to="{
                            name: 'dm.tickets.show',
                            params: { ticketId: ticketId },
                        }"
                    >
                        <mdi:alert class="text-yellow-500 cursor-pointer hover:text-yellow-600" />
                    </RouterLink>
                </div>
            </template>
            <MyTableColumn :name="t('soldTo')" property="soldTo" :sortable="false" />
            <template #soldTo="{ row }">
                <div class="flex">
                    <span class="w-full truncate" v-text="row.shipment?.soldTo" />
                </div>
            </template>
            <MyTableColumn :name="t('shipTo')" property="shipTo" :sortable="false" />
            <template #shipTo="{ row }">
                <div class="flex">
                    <span class="w-full truncate" v-text="row.shipment?.shipTo" />
                </div>
            </template>
            <MyTableColumn :name="t('references')" property="references" :sortable="false" />
            <template #references="{ row }">
                <div class="flex">
                    <span class="w-full truncate" v-text="row.shipment?.references" />
                </div>
            </template>

            <template #noDataButton>
                <MyButton
                    v-if="params.filter['start-date']"
                    plain
                    scheme="light"
                    size="small"
                    @click="clearDates()"
                >
                    <mdi:magnify class="mr-2 h-4 w-4" />
                    {{ t('searchAllTime') }}
                </MyButton>
            </template>
        </MyTable>
    </MyPanel>

    <ManageColliModal
        v-model="colliModalOpen"
        :colli="selectedColli"
        @close="colliModalClosed"
        @saved="refetch()"
    />
</template>
