import { DashboardWidget, WidgetType } from '@/types/dashboard'
import { LicenseType } from '@/types/company'
import { licenseObject } from '@/stores/auth-store'

export function isWidgetOfType<Type extends WidgetType>(
    widget: DashboardWidget,
    type: Type,
): widget is DashboardWidget<Type> {
    return widget.type === type
}

export interface WidgetConfig {
    name: string
    description: string
    moduleLicense?: LicenseType
    type: WidgetType
    defaults: {
        height: number
        width: number
    }
    shouldShow?: (licenses: licenseObject) => boolean
}

export const LicenseTypes: Partial<Record<LicenseType, string>> = {
    [LicenseType.PackagingModule]: 'packagingModule',
}

export const widgetConfig: Record<WidgetType, WidgetConfig> = {
    [WidgetType.PackagingStock]: {
        name: 'packagingStockWidget',
        description: 'packagingStockWidgetDescription',
        moduleLicense: LicenseType.PackagingModule,
        type: WidgetType.PackagingStock,
        defaults: {
            height: 4,
            width: 3,
        },
        shouldShow(licenses) {
            return licenses[LicenseType.Stock] !== undefined
        },
    },
    [WidgetType.PackagingBalance]: {
        name: 'packagingBalanceWidget',
        description: 'packagingBalanceWidgetDescription',
        moduleLicense: LicenseType.PackagingModule,
        type: WidgetType.PackagingBalance,
        defaults: {
            height: 3,
            width: 7,
        },
    },
    [WidgetType.PackagingEmployeeTransactions]: {
        name: 'packagingEmployeeTransactionWidget',
        description: 'packagingEmployeeTransactionWidgetDescription',
        moduleLicense: LicenseType.PackagingModule,
        type: WidgetType.PackagingEmployeeTransactions,
        defaults: {
            height: 3,
            width: 7,
        },
    },
    [WidgetType.Example]: {
        name: 'ExampleWidget',
        description: 'Shows packaging balance details',
        type: WidgetType.Example,
        defaults: {
            height: 2,
            width: 2,
        },
    },
}
